import ListStudents from '../../students/ListStudents.vue';
import AddStudent from '../../students/AddStudent.vue';
import EditStudent from '../../students/EditStudent.vue';
// import StudentView from '../../students/StudentView.vue';
// import AbsensiStudent from '../../students/AbsensiStudent.vue';
import DetailStudent from '../../students/DetailStudent.vue';
import route_name from '@/router/name';


const studentsRoute = [
    {
        path: route_name.student.student,
        name: 'students',
        component: ListStudents,
        meta: { requiresAuth: true },
        // children: [
        //     {
        //         path: route_name.student.student,
        //         name: 'list-students',
        //         component: ListStudents,
        //     },
        //     {
        //         path: route_name.student.student_add,
        //         name: 'add-students',
        //         component: AddStudent,
        //     },
        //     {
        //         path: route_name.student.student_view.path,
        //         name: route_name.student.student_view.name,
        //         component: DetailStudent,
        //     },
        //     {
        //         path: route_name.student.absensi,
        //         name: 'absensi-students',
        //         component: AbsensiStudent,
        //     },
        // ]
    },
    {
        path: route_name.student.student_add,
        name: 'add-students',
        component: AddStudent,
    },
    {
        path: route_name.student.student_edit.path,
        name: route_name.student.student_edit.name,
        component: EditStudent,
    },
    {
        path: route_name.student.student_view.path,
        name: route_name.student.student_view.name,
        component: DetailStudent,
    },
    
]

export default studentsRoute; 