<template>
    <div class="row g-2 align-items-center">
        <div class="col">
            <div class="page-pretitle">
                Daftar Semua Tahun Ajaran
            </div>
            <h2 class="page-title">
                Daftar Tahun Ajaran
            </h2>
        </div>
        <div class="col-12 col-md-auto ms-auto d-print-none">
            <div class="btn-list">
                <router-link :to=route.academicYear.add class="btn btn-primary d-none d-sm-inline-block">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
                        stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <line x1="12" y1="5" x2="12" y2="19"></line>
                        <line x1="5" y1="12" x2="19" y2="12"></line>
                    </svg>
                    Tambah Tahun Ajaran Baru
                </router-link>
                <a href="#" class="btn btn-primary d-sm-none btn-icon" data-bs-toggle="modal" data-bs-target="#modal-report"
                    aria-label="Create new report">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
                        stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <line x1="12" y1="5" x2="12" y2="19"></line>
                        <line x1="5" y1="12" x2="19" y2="12"></line>
                    </svg>
                </a>
            </div>
        </div>
    </div>
    <div class="card my-3">
        <div v-if="isLoading"><p class="text-center">Loading.....</p></div>
        <div v-else>
            <div v-if="data.length > 0">
                <TableComponent :onDelete="this.deleteAcademicYear" :onShowEntries="this.onShowEntriesChange()" :columns="this.fieldTable" :meta="this.meta" :dataType="AcademicYearModel" :data="this.dataTable"></TableComponent>
            </div>
            <div v-else>
            <NoDataFound :objectNotFound="notFoundTitle" :description="description"></NoDataFound>
            </div>
        </div>
    </div>
</template>

<script>
import route_name from '@/router/name'
import api from '@/packages/config/api';
import settings from '@/packages/config/settings';
import NoDataFound from '@/components/NoDataFound.vue';
import TableComponent from '@/components/TableComponent.vue';
import AcademicYearModel from './data/AcademicYear';
import Swal from 'sweetalert2'

export default {
    name: 'academic-year-list',
    data() {
        return {
            route: route_name,
            fieldTable: [
                {
                    'key': 'number',
                    'label': "No",
                    'isSortable': true,
                    'isHtml': false,
                },
                {
                    'key': 'periode',
                    'label': "Tahun Ajaran",
                    'isSortable': true,
                    'isHtml': false,
                },
                {
                    'key': 'semester',
                    'label': "Semester",
                    'isSortable': true,
                    'isHtml': false,
                },
                {
                    'key': 'date_start',
                    'label': "Tanggal Mulai",
                    'isSortable': true,
                    'isHtml': false,
                },
                {
                    'key': 'date_end',
                    'label': "Tanggal Akhir",
                    'isSortable': true,
                    'isHtml': false,
                },
                {
                    'key': 'status',
                    'label': "Status",
                    'isSortable': true,
                    'isHtml': false,
                },
                {
                    'key': 'action',
                    'label': "Action",
                    'isSortable': false,
                    'isHtml': false,
                    'class': 'text-nowrap',
                },
            ],
            isLoading: true,
            notFoundTitle: 'Student',
            description: 'Data student not found!, please check your data',
            meta: {},
            data: [],
            dataTable: [],
            showTable: 0,
        }
    },
    components: {
        NoDataFound,
        TableComponent,
    },
    methods: {
        async getAllAcademicYear() {
            try {
                this.data = [];
                const response = await api.GET(`${settings.ACADEMIC_YEAR_URI}`);
                if (response.success) {
                    // this.meta = response.content.meta;
                    response.content.forEach(element => {
                        const academicYear = {};
                        element.semester.forEach(semester => {
                            academicYear.slug = element.slug;
                            academicYear.period = element.period;
                            academicYear.semester = semester.semester.num;
                            academicYear.dateStart = semester.start_date;
                            academicYear.dateEnd = semester.end_date;
                            academicYear.isActive = semester.is_active;
                            this.data.push(AcademicYearModel.fromJson(academicYear));
                        });
                    });    
                }
            } catch (error) {
                console.log(`Catch : ${error}`);
            }
            console.log(this.data);
            this.isLoading = false;
        },
        dataTableCreated() {
            let num = 1;
            this.dataTable = [];
            console.log(this.data.length);
            this.data.forEach((element, index) => {
                if (this.showTable > 0) {
                    if (index + 1 <= this.showTable) {
                        let row = {
                            number: num,
                            periode: element.period,
                            semester: `Semester ${element.semester} - ${element.semester == 1 ? 'Ganjil' : 'Genap'}`,
                            date_start: element.dateStart,
                            date_end: element.dateEnd,
                            slug: element.slug,
                            num: element.semester,
                            status: element.isActive ? 'Aktif' : 'Tidak Aktif',
                            action: `<span class="dropdown">
                                        <button class="btn dropdown-toggle align-text-top" data-bs-boundary="viewport" data-bs-toggle="dropdown">Actions</button>
                                        <div class="dropdown-menu dropdown-menu-end">
                                            <a class="dropdown-item" href="#">
                                                Action
                                            </a>
                                            <a class="dropdown-item" href="#">
                                                Another action
                                            </a>
                                        </div>
                                    </span>`
                        }
                        this.dataTable.push(row);
                    }
                } else {
                    let row = {
                        number: num,
                        periode: element.period,
                        slug: element.slug,
                        semester: `Semester ${element.semester} - ${element.semester == 1 ? 'Ganjil' : 'Genap'}`,
                        date_start: element.dateStart,
                        date_end: element.dateEnd,
                        num: element.semester,
                        status: element.isActive ? 'Aktif' : 'Tidak Aktif',
                        action: ``
                    }
                    this.dataTable.push(row);
                }
                num++;
            });
        },
        onShowEntriesChange(val) {
            this.showTable = val;
            this.dataTableCreated();
        },
        async deleteAcademicYear(e) {
            try {
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then( async (result)  => {
                    if (result.isConfirmed) {
                        console.log(e.periode);
                        const response = await api.DELETE(`${settings.ACADEMIC_YEAR_URI}/${e.periode}`);
                        console.log(response);
                        if (response.success) {
                            this.getAllAcademicYear();
                            this.dataTableCreated();
                        }
                        Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        )
                    }
                });
                
            } catch (error) {
                console.log(`Catch Delete Academic Year : ${error}`);
            }
        }
    },
    created() {
        this.getAllAcademicYear();
    }
}
</script>