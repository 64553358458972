export default class Major {
    constructor(code, name, image, is_active) {
        this.code = code;
        this.name = name;
        this.image = image;
        this.is_active = is_active;
    }

    static fromJson(json) {
        return new Major(json.code, json.name, json.image, json.is_active);
    }
}

