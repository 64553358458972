const state = {
    user: null,
    token: null,
    clientId: null,
}

const getters = {
    isAuthenticated: state => !!state.user,
    CurrentUser: (state) => state.user,
    CurrentToken: (state) => state.token,
}

const actions = {

    async Token({ commit }, token) {
        commit('setToken', token);
    },

    async ClientID({ commit }, clientId) {
        commit('setClientID', clientId);
    },
  
    async LogIn({commit}, user) {
      await commit("setUser", user);
    },

    async LogOut({ commit }) {
      commit("logOut");
    },
};

const mutations = {
    setUser(state, user) {
        state.user = user;
    },
    logOut(state) {
        state.user = null;
        state.token = null;
    },
    setToken(state, token) {
        state.token = token;
    },
    setClientID(state, clientId) {
        state.clientId = clientId;
    }
};


const authState = {
    state,
    getters,
    actions,
    mutations,
}

export default authState;
