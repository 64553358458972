<template>
  <div class="theme-light">
    <div class="page" v-if="this.$store.getters.isAuthenticated">
      <sidebar></sidebar>
      <headerc></headerc>
      <div class="page-wrapper">
        <div class="container-xl pt-3">
          <!-- Page title -->
          <router-view></router-view>
        </div>
      </div>
      
    </div>
    <div class="page page-center" v-else>
      <div class="page-wrapper">
        <div class="container-xl pt-3">
          <!-- Page title -->
          <router-view></router-view>
        </div>
      </div>
    </div>  

  </div>
</template>

<script>
import sidebar from './components/layouts/SidebarComponent.vue'
import headerc from './components/layouts/HeaderComponent.vue'

export default {
  name: 'App',
  components: {
    sidebar,
    headerc,
  }
}
</script>

<style>
</style>
