import axios from "axios";
import store from "../../store";
import router from "@/router";



const GET = async (url, params, withCredentials = true) => {

    if(withCredentials) {
        axios.defaults.withCredentials = true;
        axios.defaults.headers.common['Authorization'] = `Bearer ${store.state.authState.token}`;
        axios.defaults.headers.common['Client-Id'] = `${store.state.authState.clientId}`;
    }

    return axios.get(url, {
        params: params
    }).then(response => { return response.data; }).catch(error => {
        
        if(error.response.data.http_code === 403) {
            console.log(store.commit("logOut"));
            router.push('/');
        }
        return error.response.data;
    });
}

const POST = async (url, data, withCredentials = true) => {
    if(withCredentials) {
        axios.defaults.withCredentials = true;
        axios.defaults.headers.common['Authorization'] = `Bearer ${store.state.authState.token}`;
        axios.defaults.headers.common['Client-Id'] = `${store.state.authState.clientId}`;
    }
    return await axios.post(url, data).then(response => { 
        return response;
     });
}

const PUT = async (url, data, withCredentials = true) => {
    if(withCredentials) {
        axios.defaults.withCredentials = true;
        axios.defaults.headers.common['Authorization'] = `Bearer ${store.state.authState.token}`;
        axios.defaults.headers.common['Client-Id'] = `${store.state.authState.clientId}`;
    }
    return axios.put(url, data).then(response => { return response.data; });
}

const PATCH = async (url, data, withCredentials = true) => {
    if(withCredentials) {
        axios.defaults.withCredentials = true;
        axios.defaults.headers.common['Authorization'] = `Bearer ${store.state.authState.token}`;
        axios.defaults.headers.common['Client-Id'] = `${store.state.authState.clientId}`;
    }
    return axios.patch(url, data).then(response => { return response.data; });
}

const DELETE = async (url, data, withCredentials = true) => {
    if(withCredentials) {
        axios.defaults.withCredentials = true;
        axios.defaults.headers.common['Authorization'] = `Bearer ${store.state.authState.token}`;
    }
    return axios.delete(url, data).then(response => { return response.data; });
}


const api = {
    GET,
    POST,
    PUT,
    PATCH,
    DELETE
}

export default api;
