export default class AcademicYear {
    constructor(slug, period, semester, dateStart, dateEnd, isActive) {
        this.period = period;
        this.semester = semester;
        this.slug = slug;
        this.dateStart = dateStart;
        this.dateEnd = dateEnd;
        this.isActive = isActive;
    }

    static fromJson(json) {
        return new AcademicYear(json.period, json.slug, json.semester, json.dateStart, json.dateEnd, json.isActive);
    }
}

