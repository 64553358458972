<template>
    <div class="row g-2 align-items-center">
        <div class="col">
            <!-- Page pre-title -->
            <div class="page-pretitle">
                Create New Teacher
            </div>
            <h2 class="page-title">
                ADD NEW TEACHER
            </h2>
        </div>
    </div>
    <form @submit.prevent="submit">
        <div class="card mt-3">
          <div class="card-body border-bottom py-3">

              <div class="row">
                  <div class="col-xl-6">
                      <div class="row">
                          <div class="col-md-6 col-xl-12">
                              
                              <div class="mb-3">
                                  <label class="form-label required" for="nis">NIP</label>
                                  <input type="text" class="form-control" v-model="v$.form.nip.$model" name="nis" id="nis"
                                      placeholder="NIS">
                                  <div class="input-errors" v-for="(error, index) in v$.form.nip.$errors" v-bind:key="index">
                                      <div class="text-danger">{{ error.$message }}</div>
                                  </div>
                              </div>
                                <div class="mb-3">
                                  <label class="form-label required" for="first-name">First Name</label>
                                  <input type="text" class="form-control" v-model="v$.form.first_name.$model" name="first-name" id="first-name"
                                      placeholder="First Name...">
                                  <div class="input-errors" v-for="(error, index) in v$.form.first_name.$errors" v-bind:key="index">
                                      <div class="text-danger">{{ error.$message }}</div>
                                  </div>
                              </div>
                              <div class="mb-3">
                                  <label class="form-label" for="middle-name">Middle Name</label>
                                  <input type="text" class="form-control" v-model="v$.form.middle_name.$model" name="middle-name" id="middle-name"
                                      placeholder="Middle Name...">
                                      
                              </div>
                              <div class="mb-3">
                                  <label class="form-label" for="last-name">Last Name</label>
                                  <input type="text" class="form-control" v-model="v$.form.last_name.$model" name="last-name" id="last-name"
                                      placeholder="Last Name...">
                              </div>
                              <div class="mb-3">
                                  <label class="form-label required">Gender</label>
                                  <div class="form-selectgroup">
                                      <label class="form-selectgroup-item">
                                          <input type="radio" v-model="v$.form.gender.$model" name="gender" value="laki-laki" class="form-selectgroup-input"
                                              checked="">
                                          <span class="form-selectgroup-label">
                                              Laki-laki
                                          </span>
                                      </label>
                                      <label class="form-selectgroup-item">
                                          <input type="radio" v-model="v$.form.gender.$model" name="gender" value="perempuan" class="form-selectgroup-input">
                                          <span class="form-selectgroup-label">
                                              Perempuan
                                          </span>
                                      </label>
                                  </div>
                              </div>

                              
                                
                          </div>
                      </div>
                  </div>
                  <div class="col-xl-6">
                      <div class="row">
                          <div class="col-md-6 col-xl-12">
                              <div class="mb-3">
                                  <label class="form-label required">Pendidikan Terakhir</label>
                                  <select class="form-select" v-model="v$.form.academic_title.$model">
                                        <option v-for="academic in academics" v-bind:key="academic" :value="academic">{{academic}}</option>
                                  </select>
                                  <!-- <div class="input-errors" v-for="(error, index) in v$.form.date_of_birth.$errors" v-bind:key="index">
                                      <div class="text-danger">{{ error.$message }}</div>
                                  </div> -->
                              </div>
                              
                              <div class="mb-3">
                                  <label class="form-label required">Mulai Mengajar Tanggal</label>
                                  <input class="form-control mb-2" type="date" placeholder="Select a date">
                                  <div class="input-errors" v-for="(error, index) in v$.form.start_devotion.$errors" v-bind:key="index">
                                      <div class="text-danger">{{ error.$message }}</div>
                                  </div>
                              </div>
                              
                                <div class="mb-3">
                                  <label class="form-label required">Akhir Mengajar Tanggal</label>
                                    <input class="form-control mb-2" type="date" placeholder="Select a date">
                                    <label class="form-check mb-2">
                                    <input class="form-check-input" type="checkbox">
                                    <span class="form-check-label">
                                      Masih Mengajar Sampai Sekarang
                                    </span>
                                  </label>
                                  <div class="input-errors" v-for="(error, index) in v$.form.end_devotion.$errors" v-bind:key="index">
                                      <div class="text-danger">{{ error.$message }}</div>
                                  </div>
                              </div>
                              <div class="mb-3">
                                  <div class="form-label">Status Student</div>
                                  <select class="form-select" v-model="v$.form.status.$model">
                                      <option v-for="status in this.statuses" v-bind:key="status" :value="status.slug">{{status.name}}</option>
                                </select>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="card-footer d-flex">
            <span class="d-none d-sm-inline">

                    <a href="" class="btn btn-white w-10">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-square-x" width="24"
                            height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                            stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <rect x="4" y="4" width="16" height="16" rx="2"></rect>
                            <path d="M10 10l4 4m0 -4l-4 4"></path>
                        </svg>
                        Batal
                    </a>
                </span>
                <button type="submit" class="btn ms-auto btn-primary w-10">
                    <!-- Download SVG icon from http://tabler-icons.io/i/brand-dribbble -->
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-device-floppy" width="24"
                        height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                        stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M6 4h10l4 4v10a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2"></path>
                        <circle cx="12" cy="14" r="2"></circle>
                        <polyline points="14 4 14 8 8 8 8 4"></polyline>
                    </svg>
                    Simpan
                </button>
          </div>
        </div>
    </form>
</template>

<script>
import api from '@/packages/config/api';
import settings from '@/packages/config/settings';
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import StatusModel from '../../data/StatusModel';
export default {
  name: 'Add-Teacher',
   setup () {
        return { v$: useVuelidate() }
  },
  data() {
    return {
      loading: false,
      statuses: [],
      academics: [
        'SMA/Sederajat',
        'DIPLOMA - I/II/III',
        'S1 - Sarjana',
        'S2 - Magister',
        'S3 - Doktor',
      ],
      form: {
        nip: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        gender: 'laki-laki',
        academic_title: 'SMA/Sederajat',
        start_devotion: undefined,
        end_devotion: undefined,
        status: undefined,
      }
    }
  },
  validations(){
    return{
        form: {
            nip: {
                required,
            },
            first_name: {
                required,
            },
            last_name: {
                required,
            },
            middle_name: {
                required,
            },
            academic_title: {
                required,
            },
            gender: {
                required,
            },
            start_devotion: {
                required,
            },
            end_devotion: {
                required,
            },
            status: {
                required,
            },

        }
      }
  },
  methods: {
    async fetchStatuses(){
        this.statuses = [];
        try {
            const response = await api.GET(settings.STUDENT_URI_STATUS);
            
            if (response.success) {
                
                response.content.forEach(element => {
                    this.statuses.push(StatusModel.fromJson(element));
                });
                this.form.status = this.statuses[0].slug;
            }
        } catch (error) {
            console.log(error);
        }

        console.log(this.form.status);
    
    },
    async submit() {
      
    }
  },
  created() {
    this.fetchStatuses();
  }
}
</script>