<template>
    <div class="row g-2 align-items-center">
        <div class="col">
            <!-- Page pre-title -->
            <div class="page-pretitle">
                Tambah Kelas Baru
            </div>
            <h2 class="page-title">
                TAMBAH KELAS BARU
            </h2>
        </div>
    </div>
	<div class="card my-3">
        <div class="card-body border-bottom py-3">
            <div class="row">
                <div class="col-xl-6">
                    <div class="row">
                        <div class="col-md-6 col-xl-12">
                            <div class="mb-3">
                                <label class="form-label required" for="first-name">Kode Kelas</label>
                                <input v-model="classCode" type="text" class="form-control" name="first-name" id="first-name"
                                    placeholder="X - Multimedia">
                            </div>
                            <div class="mb-3">
                                <div class="form-label">Tingkat</div>
                                <select class="form-select" v-model="level">
                                    <option value="">-- PILIH TINGKAT KELAS --</option>
                                    <option value="10">X</option>
                                    <option value="11">XI</option>
                                    <option value="12">XII</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
				<div class="col-xl-6">
                    <div class="row">
                        <div class="col-md-6 col-xl-12">
                            <div class="mb-3">
                                <div class="form-label">Jurusan</div>
                                <select class="form-select" v-model="vocational">
                                    <option value="">-- PILIH TINGKAT JURUSAN --</option>
                                    <option v-for="major in dataMajor" v-bind:key="major.code" :value="major.code">{{major.code}} - {{major.name}}</option>
                                </select>
                            </div>
							<div class="mb-3">
                                <div class="form-label">Status Kelas</div>
                                <label class="form-check form-switch">
                                    <input class="form-check-input" v-model="status" type="checkbox" name="global-status">
                                    <span class="form-check-label">Aktif</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
	<div class="card">
        <div class="card-body d-flex">
            <a href="#" v-on:click="this.methodClass"  class="btn ms-auto btn-primary w-10">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-device-floppy" width="24"
                    height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                    stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M6 4h10l4 4v10a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2"></path>
                    <circle cx="12" cy="14" r="2"></circle>
                    <polyline points="14 4 14 8 8 8 8 4"></polyline>
                </svg>
                Simpan {{path_name}}
            </a>
        </div>
    </div>

</template>

<script>
import route_name from '@/router/name';
import api from '@/packages/config/api';
import settings from '@/packages/config/settings';
import MajorModel from '../majors/data/Major';
import ClassRoom from './data/ClassRoom';

export default {
    name: 'add-class',
    data() {
        return {
            route: route_name,
            dataMajor: [],
            level: "",
            vocational: "",
            isEdit: false,
            codeClass: Object,
        }
    },
    methods: {
        async getAllMajor() {
            try {
                const response = await api.GET(`${settings.VOCATIONAL_URI}`);
                if (response.success) {
                    this.dataMajor = [];
                    response.content.forEach(element => {
                        let major = MajorModel.fromJson(element);
                        this.dataMajor.push(major);
                    });
                }
            } catch (error) {
                console.log(`Catch Get All Major : ${error}`);
            }
        },
        async methodClass() {
            if (!this.isEdit) {
                this.createClass();
            } else {
                this.updateClass();
            }
        },
        async createClass() {
            try {
                const data = {
                    "code": this.classCode,
                    "int_level": this.level,
                    "vocational": this.vocational,
                    "is_active": this.status
                }
                console.log(data);
                const response = await api.POST(`${settings.CLASSROOM_URI}/`, data);
                if (response.data.success) {
                    console.log("Data berhasil di update");
                    this.$router.push(this.route.classRoom.list);
                }
            } catch (error) {
                console.log(`Catch Error Create Class : ${error}`);
            }
        },
        async updateClass() {
            try {
                const data = {
                    "code": this.classCode,
                    "int_level": this.level,
                    "vocational": this.vocational,
                    "is_active": this.status
                }
                console.log(data);
                const response = await api.PATCH(`${settings.CLASSROOM_URI}/${this.codeClass.code}`, data);
                if (response.success) {
                    console.log("Data berhasil di update");
                    this.$router.push(this.route.classRoom.list);
                }
            } catch (error) {
                console.log(`Catch Error Update Class : ${error}`);
            }
        }
    },
    created() {
        this.getAllMajor();
    },
    mounted() {
        this.isEdit = this.$route.params.isEdit;
        if (this.isEdit) {
            this.codeClass = ClassRoom.fromJson(JSON.parse(this.$route.params.data));
            this.classCode = this.codeClass.code;
            this.vocational = this.codeClass.vocational.code;
            this.level = this.codeClass.level.num;
            this.status = this.codeClass.is_active;
        }
    }
}
</script>