<template>
    <div class="row g-2 align-items-center">
        <div class="col">
            <!-- Page pre-title -->
            <div class="page-pretitle">
                Tambah Tahun Ajaran Baru
            </div>
            <h2 class="page-title">
                TAMBAH TAHUN AJARAN BARU
            </h2>
        </div>
    </div>
	<div class="card my-3">
        <div class="card-body border-bottom py-3">
            <div class="row">
                <div class="col-xl-6">
                    <div class="row">
                        <div class="col-md-6 col-xl-12">
                            <div class="mb-4">
                                <label class="form-label required" for="first-name">Tahun Ajaran</label>
                                <Datepicker v-model="academicYear" yearPicker :minDate="this.unAvailableDate" inputClassName="dp-custom-input" required
                                    placeholder="Tahun Ajaran" autoApply name="periode" format="yyyy">
                                    <template #input-icon>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon input-slot-icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><rect x="4" y="5" width="16" height="16" rx="2" /><line x1="16" y1="3" x2="16" y2="7" /><line x1="8" y1="3" x2="8" y2="7" /><line x1="4" y1="11" x2="20" y2="11" /><line x1="11" y1="15" x2="12" y2="15" /><line x1="12" y1="15" x2="12" y2="18" /></svg>
                                    </template>
                                </Datepicker>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6">
                    <div class="row">
                        <div class="col-md-6 col-xl-12">
                            <div class="mb-5">
                                <div class="form-label">Status</div>
                                <label class="form-check form-switch">
                                    <input class="form-check-input" type="checkbox" name="global-status">
                                    <span class="form-check-label">Aktif</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-6">
                    <div class="row">
                        <div class="col-md-6 col-xl-12">
                            <div class="mb-3">
                                <div class="page-pretitle">
                                    Semester I
                                </div>
                            </div>
                            <div class="mb-3">
                                <div class="form-label required">Tanggal Mulai</div>
                                <Datepicker v-model="dateStartSmtI" :minDate="this.unAvailableDate" inputClassName="dp-custom-input" :enableTimePicker="false" required
                                    placeholder="Select a Date" autoApply name="date-start-I" modelType="dd-MM-yyyy" format="dd-MM-yyyy">
                                    <template #input-icon>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon input-slot-icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><rect x="4" y="5" width="16" height="16" rx="2" /><line x1="16" y1="3" x2="16" y2="7" /><line x1="8" y1="3" x2="8" y2="7" /><line x1="4" y1="11" x2="20" y2="11" /><line x1="11" y1="15" x2="12" y2="15" /><line x1="12" y1="15" x2="12" y2="18" /></svg>
                                    </template>
                                </Datepicker>
                            </div>
                            <div class="mb-3">
                                <div class="form-label required">Tanggal Akhir</div>
                                <Datepicker v-model="dateEndSmtI" :minDate="dateStartSmtI" 
                                inputClassName="dp-custom-input" :enableTimePicker="false" required
                                    placeholder="Select a Date" autoApply name="date-end-I" modelType="dd-MM-yyyy" format="dd-MM-yyyy">
                                    <template #input-icon>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon input-slot-icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><rect x="4" y="5" width="16" height="16" rx="2" /><line x1="16" y1="3" x2="16" y2="7" /><line x1="8" y1="3" x2="8" y2="7" /><line x1="4" y1="11" x2="20" y2="11" /><line x1="11" y1="15" x2="12" y2="15" /><line x1="12" y1="15" x2="12" y2="18" /></svg>
                                    </template>
                                </Datepicker>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6">
                    <div class="row">
                        <div class="col-md-6 col-xl-12">
                            <div class="mb-3">
                                <div class="page-pretitle">
                                    Semester II
                                </div>
                            </div>
                            <div class="mb-3">
                                <div class="form-label required">Tanggal Mulai</div>
                                <Datepicker v-model="dateStartSmtII" inputClassName="dp-custom-input" :enableTimePicker="false" required
                                    placeholder="Select a Date" autoApply name="date-start-II" modelType="dd-MM-yyyy" format="dd-MM-yyyy">
                                    <template #input-icon>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon input-slot-icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><rect x="4" y="5" width="16" height="16" rx="2" /><line x1="16" y1="3" x2="16" y2="7" /><line x1="8" y1="3" x2="8" y2="7" /><line x1="4" y1="11" x2="20" y2="11" /><line x1="11" y1="15" x2="12" y2="15" /><line x1="12" y1="15" x2="12" y2="18" /></svg>
                                    </template>
                                </Datepicker>
                            </div>
                            <div class="mb-3">
                                <div class="form-label required">Tanggal Akhir</div>
                                <Datepicker v-model="dateEndSmtII" inputClassName="dp-custom-input" :enableTimePicker="false" required
                                    placeholder="Select a Date" autoApply name="date-end-II" modelType="dd-MM-yyyy" format="dd-MM-yyyy">
                                    <template #input-icon>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon input-slot-icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><rect x="4" y="5" width="16" height="16" rx="2" /><line x1="16" y1="3" x2="16" y2="7" /><line x1="8" y1="3" x2="8" y2="7" /><line x1="4" y1="11" x2="20" y2="11" /><line x1="11" y1="15" x2="12" y2="15" /><line x1="12" y1="15" x2="12" y2="18" /></svg>
                                    </template>
                                </Datepicker>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
	<div class="card">
        <div class="card-body d-flex">
            <a href="#" v-on:click="this.createAcademicYear" class="btn ms-auto btn-primary w-10">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-device-floppy" width="24"
                    height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                    stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M6 4h10l4 4v10a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2"></path>
                    <circle cx="12" cy="14" r="2"></circle>
                    <polyline points="14 4 14 8 8 8 8 4"></polyline>
                </svg>
                Simpan
            </a>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import route_name from '@/router/name'
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import api from '@/packages/config/api';
import settings from '@/packages/config/settings';
import UnAvailableDateModel from './data/UnAvailableDate';

export default {
    components: { Datepicker },
    name: 'add-academic-year',
    setup() {
        const academicYear = ref();
        const dateStartSmtI = ref();
        const dateEndSmtI = ref();
        const dateStartSmtII = ref();
        const dateEndSmtII = ref();
        return {
            academicYear,
            dateStartSmtI,
            dateEndSmtI,
            dateStartSmtII,
            dateEndSmtII,
        }
    },
    data() {
        return {
            route: route_name,
            unAvailableDate: new Date(),
            minDateEndSmtI: new Date(),
        }
    },
    methods: {
        async getUnAvailableDate() {
            try {
                const response = await api.GET(`${settings.ACADEMIC_YEAR_UNAVAILABLE_URI}`);
                if (response.success) {
                    response.content.forEach(element => {
                        const _unAvailableDate = UnAvailableDateModel.fromJson(element);
                        const _split = _unAvailableDate.dateEnd.split('-');
                        const _year = _split[2];
                        const _month = _split[1];
                        const _day = _split[0];
                        this.unAvailableDate = new Date(_year, _month, parseInt(_day) + 1);
                    });
                }
            } catch (error) {
                console.log(`Catch Get Unavailable Date : ${error}`);
            }
        },
        async createAcademicYear() {
            const data = {
                'year': this.academicYear,
                'semester': [
                    {
                        'semester': 1,
                        'start_date': this.dateStartSmtI,
                        'end_date': this.dateEndSmtI
                    },
                    {
                        'semester': 2,
                        'start_date': this.dateStartSmtII,
                        'end_date': this.dateEndSmtII
                    }
                ]
            }
            console.log(this.academicYear);
            try {
                const response = await api.POST(`${settings.ACADEMIC_YEAR_URI}/`, data);
                console.log(`Success : ${response}`);
                if (response.data.success) {
                    console.log("Data berhasil di input");
                    this.$router.push(this.route.academicYear.list);
                }
            } catch (error) {
                console.log(`Catch : ${error}`);
            }
        }
    },
    created() {
        this.getUnAvailableDate();
    }
}
</script>

<style lang="css">
.dp-custom-input {
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.4285714286;
}
.input-slot-icon {
    height: 20px;
    width: auto;
    margin-left: 7px;
}
</style>