const route_name = {
    dashboard: '/',
    auth: {
        internal: '/login',
    },
    teacher: {
        path: '/teacher/',
        list: '/teacher/list-teacher',
        absensi: '/teacher/absensi-teacher',
        add: {
            path: '/teacher/add/',
            name: 'add-teacher'
        }
    },
    subject: {
        path: '/subject/',
        list: '/subject/list',
        add: '/subject/add',
        edit: '/subject/edit/:code',
        detail: '/subject/detail/:code'
    },
    student: {
        student: '/student/',
        list: '/student/list',
        absensi: '/absensi',
        student_add: '/student/add',
        student_edit: {
            path: '/student/edit',
            name: 'student-edit'
        },
        student_view: {
            path: '/student/detail/:uid',
            name: 'student-detail',
        },
    },
    classRoom: {
        path: '/classroom/',
        list: '/classroom/list',
        form: '/classroom/form',
    },
    major: {
        path: '/major/',
        list: '/major/list',
        add: '/major/add',
        edit: '/major/edit/:code',
        detail: '/major/detail/:code'

    },
    academicYear: {
        path: '/academic-year/',
        list: '/academic-year/list',
        add: '/academic-year/add',
    },
    notFound: '/404',
}

export default route_name;