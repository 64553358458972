export default class StatusModel {
    slug = "";
    name = "";

    constructor(slug, name) {
        this.slug = slug;
        this.name = name;
    }

    static fromJson(json) {
        return new StatusModel(json.slug, json.name);
    }
}