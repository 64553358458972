<template>
   <div>
        <div class="card-body border-bottom py-3">

            <div class="d-flex">
                <div class="text-muted">
                    Show
                    <div class="mx-3 d-inline-block">
                        <div class="col-12">
                            <select v-model="showEntries" @change="onShowEntries(showEntries)" class="form-select">
                                  <option v-for="entries in selectedEntries" v-bind:key="entries" v-bind:value="entries">{{entries}}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="ms-auto text-muted">
                    Search:
                    <div class="ms-2 d-inline-block">
                        <input type="text" v-model="query" v-on:keyup="onSearch(query)" class="form-control form-control-sm" aria-label="Search invoice">
                    </div>
                </div>
            </div>

        </div>
        <div class="table-responsive">
            <table class="table card-table table-vcenter text-nowrap datatable">
                <thead>
                    <tr>
                        <th v-for="field in columns" v-bind:key="field.key">{{field.label}}</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="(row) in data" v-bind:key="row">

                        <td v-for="field in columns" v-bind:key="field.key" :class="field.class">
                            <span v-if="field.isHtml" v-html="row[field.key]"></span>
                            <span v-else>
                                <span v-if="field.key == 'action'">
                                    <ActionTable :onDelete="onDelete" :onEdit="onEdit" :params="`${row.params}`" :data="row.singleData"></ActionTable>
                                </span>
                                <span v-else>
                                    <span v-if="field.isLink">
                                        <router-link :to="{ name: field.to, params: { [field.key]: `${row.params}`} }">{{row[field.key]}}</router-link>
                                    </span>
                                    <span v-else>
                                        {{row[field.key]}}
                                    </span>
                                </span>
                            </span>
                        </td>
                        
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="card-footer d-flex align-items-center">
            <p class="m-0 text-muted">Showing <span>{{this.bottomPagination.from}} to {{this.bottomPagination.to}}</span> of <span>{{bottomPagination.countAllData}}</span> entries</p>
           <ul class="pagination m-0 ms-auto">
                <li class="page-item">
                    <button class="page-link" @click="this.onPrev">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><polyline points="15 6 9 12 15 18"></polyline></svg>
                        prev
                    </button>
                </li>
                <li class="page-item" v-for="page in bottomPagination.countAllPage" v-bind:key="page" :class="this.currenPaginationPage === page ? 'active': ''"><button class="page-link" @click="this.onPageClick(page)">{{page}}</button></li>
                <li class="page-item">
                    <button class="page-link" @click="this.onNext">
                        next
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><polyline points="9 6 15 12 9 18"></polyline></svg>
                    </button>
                </li>
            </ul>
        </div>
   </div>
            
</template>

<script>

import ActionTable from '@/components/ActionButton';
export default {
    name: 'table-component',
    data() {
        return{
            query: '',
            selectedEntries: [10, 50, 100, 500],
            showEntries: 10,
            bottomPagination: {
                currentPage: 1,
                lastPage: 1,
                countAllData: 0,
                countAllPage: 0,
                from: 0,
                to: 0,
                offset: 1,
            },
        }
    },
    props: {
        onRowClick: {
            type: Function,
            default: () => {},
        },
        currenPaginationPage: {
            type: Number,
            default: 1,
        },
        meta: {
            type: Object,
            required: true,
        },
        data: {
            type: Array,
            required: true
        },
        columns: {
            type: Array,
            required: true
        },
        onShowEntries: {
            type: Function,
            required: true,
        },
        onSearch: {
            type: Function,
            required: false
        },
        paginationActive: {
            type: Number,
            default: 1,
        },
        onPageClick: {
            type: Function,
            required: true
        },
        onNext: {
            type: Function,
            required: true
        },
        onPrev: {
            type: Function,
            required: true
        },
        onEdit: {
            type: Function,
            required: false
        },
        onDelete: {
            type: Function,
            required: false
        },
    },
    components: {
        ActionTable
    },
    methods: {
        async createPagginate() {
            this.bottomPagination.countAllData = this.meta.pagination.total
            this.bottomPagination.countAllPage = this.meta.pagination.last_page
            this.bottomPagination.currentPage = this.meta.pagination.current_page
            this.bottomPagination.from = this.meta.pagination.from
            this.bottomPagination.to = this.meta.pagination.to
            console.log(this.meta.pagination)
        },
    },
    updated() {
        this.createPagginate()
    },
    created() {
        this.createPagginate()
    },
}   
</script>