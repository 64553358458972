<template>
    <div class="empty">
        
        <p class="empty-title">{{objectNotFound}} Not Found</p>
        <p class="empty-subtitle text-muted">
            {{description}}
        </p>
        <div class="empty-action">
           <div v-if="action != null" v-html="action"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NoDataFound',
    props: [
        'objectNotFound',
        'description',
        'action',
    ],
}
</script>
