import ListSubject from '../../subjects/ListSubjects.vue';
import Add from '../../subjects/AddSubject.vue';
import SubjectView from '../../subjects/SubjectView.vue';
import EditSubject from '../../subjects/EditSubject.vue';
import DetailSubject from '../../subjects/DetailSubject.vue'
import route_name from '@/router/name'

const subjectRoute = [
    {
        path: route_name.subject.path,
        name: 'subject',
        component: SubjectView,
        meta: { requiresAuth: true },
        children: [
            {
                path: route_name.subject.list,
                name: 'list-subject',
                component: ListSubject,
            },
            {
                path: route_name.subject.add,
                name: 'add-subject',
                component: Add,
            },
            {
                path: route_name.subject.edit,
                name: 'edit-subject',
                component: EditSubject,
            },
            {
                path: route_name.subject.detail,
                name: 'detail-subject',
                component: DetailSubject,
            },
        ]
    }
]

export default subjectRoute; 