<template>
    <div class="row g-2 align-items-center">
        <div class="col">
            <!-- Page pre-title -->
            <div class="page-pretitle">
                List off All Students
            </div>
            <h2 class="page-title">
                STUDENT LIST
            </h2>
        </div>
        <!-- Page title actions -->
        <div class="col-12 col-md-auto ms-auto d-print-none">
            <div class="btn-list">

                <router-link :to="this.route.student.student_add" class="btn btn-primary d-none d-sm-inline-block">
                   
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
                        stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <line x1="12" y1="5" x2="12" y2="19"></line>
                        <line x1="5" y1="12" x2="19" y2="12"></line>
                    </svg>
                    Add New Student
                </router-link>
                
                <a href="#" class="btn btn-primary d-sm-none btn-icon" data-bs-toggle="modal" data-bs-target="#modal-report"
                    aria-label="Create new report">
                    <!-- Download SVG icon from http://tabler-icons.io/i/plus -->
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
                        stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <line x1="12" y1="5" x2="12" y2="19"></line>
                        <line x1="5" y1="12" x2="19" y2="12"></line>
                    </svg>
                </a>
            </div>
        </div>
    </div>
    <div class="card my-3">
    <div v-if="this.isLoading">
        <p class="text-center">Loading.......</p>
    </div>
        
    <div v-else>
       <TableComponent :onEdit="this.onEdit" :onNext="this.onNext" :onPrev="this.onPrev" :onDelete="this.onDelete" :paginationActive="this.paginationActive" :currenPaginationPage="this.currentPageActive" :onSearch="this.onSearch" :onShowEntries="this.onShowEntriesChange" :onPageClick="this.onPageClick" :columns="this.fieldTable" :dataType="StudentModel" :data="this.dataTable" :meta="this.meta"></TableComponent>
    </div>
    </div>
</template>

<script>
import api from '@/packages/config/api';
import settings from '@/packages/config/settings';
import TableComponent from '@/components/TableComponent.vue';
import StudentModel from './data/Students';
import route_name from '@/router/name';
import Swal from 'sweetalert2'
export default {
   
    name: 'student-list',
    data() {
        return {
            route: route_name,
            fieldTable: [
                {
                    key: 'no',
                    label: 'No',
                    isSortable: true,
                    isHtml: false,
                },
                {
                    key: 'uid',
                    label: 'NISN',
                    isSortable: true,
                    isHtml: false,
                    isLink: true,
                    to: route_name.student.student_view.name,
                },
                {
                    key: 'nis',
                    label: 'NIS',
                    isSortable: true,
                    isHtml: false,
                },
                {
                    key: 'full_name',
                    label: 'Full Name',
                    isSortable: true,
                    isHtml: false,
                },
                {
                    key: 'academic_year',
                    label: 'Academic Year',
                    isSortable: true,
                    isHtml: false,
                },
                {
                    key: 'classroom',
                    label: 'Classroom',
                    isSortable: true,
                    isHtml: false,
                },
                {
                    key: 'status',
                    label: 'Status',
                    isSortable: true,
                    isHtml: false,
                },
                {
                    key: 'action',
                    label: 'Action',
                    isSortable: true,
                    isHtml: false,
                },
            ],
            isLoading: true,
            notFoundTitle: 'Student',
            description: 'Data student not found!, please check your data',
            meta: {},
            data: [],
            dataTable: [],
            dataPerPage: 2,
            currentPageActive: 1,
            paginationActive: 1,
            offset: 0,
            q: '',
            
        }
    },

    components: {
        TableComponent
    },
    methods: {
        async onNext() {
            if (this.currentPageActive < this.meta.pagination.last_page) {
                this.currentPageActive++;
                await this.allStudents();
            } 
        },
        async onPrev() {
            if (this.currentPageActive > 1) {
                this.currentPageActive--;
                await this.allStudents();
            } 
        },
        async onRowClick(uid) {
            this.$router.push({
                    name: route_name.student.student_view.name,
                    params: {
                        uid: uid
                    },
                });
        },
        async onEdit(data) {
            console.log(data);
            this.$router.push({
                    name: route_name.student.student_edit.name,
                    params: {
                        uid: data.params
                    },
                });
        },
        async onDelete(data) {
            
           try {
                 Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then( async (result)  => {
                    if (result.isConfirmed) {
                        const deleted = await api.DELETE(settings.STUDENT_URI + data.params);
                        if (deleted.success) {
                            Swal.fire(
                                'Deleted!',
                                'Your file has been deleted.',
                                'success'
                            )
                            await this.allStudents();
                        }
                    }
                });
                
           } catch (error) {
                console.log(error);
           }
        },
        async onSearch(val){
            this.q = val;
            await this.allStudents();
        },
        dataTableCreated() {
            this.dataTable = [];
            this.data.forEach((element, index) => {
            
            let row = {
                no: this.offset + (index + 1),
                uid: element.nisn,
                nis: element.nis,
                full_name: element.full_name,
                academic_year: element.academic_year,
                classroom: element.classroom,
                params: element.uid,
                status: element.status.name,
            }
            this.dataTable.push(row);
            });
        },
        async onShowEntriesChange(val) {
            this.dataPerPage = val;  
            this.offset = (this.dataPerPage * this.currentPageActive) - this.dataPerPage,
        
            await this.allStudents();
        },
        async onPageClick(val, paginationActive){
            this.currentPageActive = val;
            this.paginationActive = paginationActive;
            this.offset = (this.dataPerPage * this.currentPageActive) - this.dataPerPage,
            await this.allStudents();
        },
        async allStudents() {
            // this.isLoading = true;
            try {
                const response = await api.GET(`${settings.STUDENT_URI}?pos=${this.currentPageActive}&len=${this.dataPerPage}&offset=${this.offset}&q=${this.q}`);
               
                if(response.success){
                    this.meta = response.content.meta;
                    this.data = [];
                    response.content.data.forEach(element => {
                        let student = StudentModel.fromJson(element);
                        this.data.push(student);
                    });

                    this.dataTableCreated();
                }
                
            } catch (error) {
                console.log(`Error: ${error}`);
            }
         
            this.isLoading = false;
        
        },

    },
    
    created() {
        this.allStudents();
    }
}
</script>