<template>
    <div class="row g-2 align-items-center">
        <div class="col">
            <div class="page-pretitle">
                Daftar Semua Mata Pelajaran
            </div>
            <h2 class="page-title">
                Daftar Mata Pelajaran
            </h2>
        </div>
        <div class="col-12 col-md-auto ms-auto d-print-none">
            <div class="btn-list">
                <router-link :to=route.subject.add class="btn btn-primary d-none d-sm-inline-block">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
                        stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <line x1="12" y1="5" x2="12" y2="19"></line>
                        <line x1="5" y1="12" x2="19" y2="12"></line>
                    </svg>
                    Tambah Pelajaran Baru
                </router-link>
                <a href="#" class="btn btn-primary d-sm-none btn-icon" data-bs-toggle="modal" data-bs-target="#modal-report"
                    aria-label="Create new report">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
                        stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <line x1="12" y1="5" x2="12" y2="19"></line>
                        <line x1="5" y1="12" x2="19" y2="12"></line>
                    </svg>
                </a>
            </div>
        </div>
    </div>
    <div class="card my-3">
        <div v-if="isLoading"><p class="text-center">Loading.....</p></div>
        <div v-else>
            <div v-if="data.length > 0">
                <TableComponent :onEdit="this.onEditSubject" :onDelete="this.onDeleteSubject" :onNext="this.onNext" :onPrev="this.onPrev" :currenPaginationPage="this.currentPageActive" :onSearch="this.onSearch"  :onShowEntries="this.onShowEntriesChange" :onPageClick="this.onPageClick" :columns="this.fieldTable" :pagination="this.pagination" :dataType="SubjectModel" :data="this.dataTable" :meta="this.meta"></TableComponent>
            </div>
            <div v-else>
                <NoDataFound :objectNotFound="notFoundTitle" :description="description"></NoDataFound>
            </div>
        </div>
    </div>
</template>

<script>
import route_name from '@/router/name'
import api from '@/packages/config/api';
import settings from '@/packages/config/settings';
import NoDataFound from '@/components/NoDataFound.vue';
import TableComponent from '@/components/TableComponent.vue';
import SubjectModel from './data/Subject';
import Swal from 'sweetalert2'

export default {
    name: 'subject-list',
    data() {
        return {
            route: route_name,
            fieldTable: [
                {
                    'key': 'number',
                    'label': "No",
                    'isSortable': true,
                    'isHtml': false,
                    'isLink': false,
                },
                {
                    'key': 'code',
                    'label': "Kode Mata Pelajaran",
                    'isSortable': true,
                    'isHtml': false,
                    'isLink': true,
                    'to': 'detail-subject'
                },
                {
                    'key': 'name',
                    'label': "Nama",
                    'isSortable': true,
                    'isHtml': false,
                    'isLink': false,
                },
                {
                    'key': 'level',
                    'label': "Tingkat",
                    'isSortable': true,
                    'isHtml': false,
                    'isLink': false,
                },
                {
                    'key': 'status',
                    'label': "Status",
                    'isSortable': true,
                    'isHtml': true,
                    'isLink': false,
                },
                {
                    'key': 'action',
                    'label': "Action",
                    'isSortable': false,
                    'isHtml': false,
                    'class': 'text-nowrap',
                    'isLink': false,
                },
            ],
            isLoading: true,
            notFoundTitle: 'Mata Pelajaran',
            description: 'Data Mata pelajaran tidak ditemukan!, Harap periksa kembali data anda',
            meta: {},
            data: [],
            dataTable: [],
            dataPerPage: 10,
            currentPageActive: 1,
            offset: 0,
            q: '',
        }
    },
    components: {
        TableComponent,
        NoDataFound
    },
    methods: {
        async onSearch(val) {
            this.q = val;
            await this.getAllSubject();
        },
        async onShowEntriesChange(val) {
            this.dataPerPage = val;  
            this.offset = (this.dataPerPage * this.currentPageActive) - this.dataPerPage,
        
            await this.getAllSubject();
            
        },
        async onPageClick(val) {
            if (this.currentPageActive != val) {
                this.currentPageActive = val;
                this.offset = (this.dataPerPage * this.currentPageActive) - this.dataPerPage,
                await this.getAllSubject();
            } 
        },
        async onNext() {
            if (this.currentPageActive < this.meta.pagination.last_page) {
                this.currentPageActive++;
                await this.getAllSubject();
            } 
        },
        async onPrev() {
            if (this.currentPageActive > 1) {
                this.currentPageActive--;
                await this.getAllSubject();   
            } 
        },
        dataTableCreated() {
            this.dataTable = [];
            let num = 1;
            this.data.forEach(element => {
                let row = {
                    number: num++,
                    level: element.level,
                    code: element.code,
                    name: element.name,
                    params: element.code,
                    singleData: element,
                    status: `<span class="badge ${element.is_active ? 'bg-success' : 'bg-danger'} me-1"></span> ${element.is_active ? 'Aktif' : 'Tidak Aktif'}`
                }
                this.dataTable.push(row);
            });
        },
        async getAllSubject() {
            try {
                const response = await api.GET(`${settings.SUBJECT_URI}?pos=${this.currentPageActive}&len=${this.dataPerPage}&offset=${this.offset}&q=${this.q}`);
                if (response.success) {
                    this.meta = response.content.meta;

                    this.data = [];
                    response.content.data.forEach(element => {
                        let subject = SubjectModel.fromJson(element);
                        this.data.push(subject);
                    });
                    this.dataTableCreated();
                }
            } catch (e) {
                console.log(e);
            }
            this.isLoading = false;
        },
        async onEditSubject(row) {
            this.$router.push({
                name: "edit-subject",
                params: {code: row.data.code },
            });
        },
        async onDeleteSubject(row) {
            try {
                console.log(row.params);
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then( async (result)  => {
                    if (result.isConfirmed) {
                        const response = await api.DELETE(`${settings.SUBJECT_URI}/${row.params}`);
                        if (response.success) {
                            Swal.fire(
                                'Deleted!',
                                'Your file has been deleted.',
                                'success'
                            )
                            this.getAllSubject();
                            this.dataTableCreated();
                        }
                    }
                });
                
            } catch (error) {
                console.log(`Catch Delete Subject : ${error}`);
            }
        }
    },
    created() {
        this.getAllSubject()
    }
}
</script>