import { createApp } from 'vue';
import App from './App.vue'
import router from './router';
import store from './store';
import settings from './packages/config/settings';
import axios from 'axios';
import 'vue-mc';

import '@tabler/core/dist/css/tabler.min.css'
import '@tabler/core/dist/css/demo.min.css'
import '@tabler/core/dist/js/tabler.min.js'
import '@tabler/core/dist/js/demo.min.js'


axios.defaults.withCredentials = false;
axios.defaults.baseURL = settings.API_URI;
axios.defaults.headers.common['Time-Zone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;

// axios.interceptors.response.use(undefined, function(error) {
//     if (error) {
//       const originalRequest = error.config;
//       if (error.response.status === 401 && !originalRequest._retry) {
//         originalRequest._retry = true;
//         // store.dispatch("LogOut");
//         this.$router.push('/');
//       }
//     }
// });

const app = createApp(App);
app.use(router);
app.use(store);
app.mount('#app');
