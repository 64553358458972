import Vuex from "vuex";
import { createApp } from 'vue';
import createPersistedState from 'vuex-persistedstate'
import createMigrate from 'vuex-persistedstate-migrate'
import authState from "./states/auth_state";

const migrations = [
    {
      version: 1,
      up: state => {
        return {
          ...state,
          myModule: {
            ...state.myModule,
            myProperty: 'migrated value',
          }
        }
      }
    }
]

createApp(Vuex);


export default new Vuex.Store({
    modules: {
        authState,
    },
    plugins: [
        createPersistedState({
            getState: createMigrate(migrations, 'migration.version'),
        })
    ],
});