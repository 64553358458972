import ListAcademicYear from '../../academic-year/ListAcademicYear.vue';
import Add from '../../academic-year/AddAcademicYear.vue';
import AcademicYearView from '../../academic-year/AcademicYearView.vue';
import route_name from '@/router/name';

const academicYearRoute = [
    {
        path: route_name.academicYear.path,
        name: 'academic-year',
        component: AcademicYearView,
        meta: { requiresAuth: true },
        children: [
            {
                path: route_name.academicYear.list,
                name: 'list-academic-year',
                component: ListAcademicYear,
            },
            {
                path: route_name.academicYear.add,
                name: 'add-academic-year',
                component: Add,
            },
        ]
    }
]

export default academicYearRoute; 