import Level from "./Level";
import Major from "../../majors/data/Major";
export default class ClassRoom {
    constructor(level, vocational, code, is_active) {
        this.level = level;
        this.vocational = vocational;
        this.code = code;
        this.is_active = is_active;
    }

    static fromJson(json) {
        return new ClassRoom(Level.fromJson(json.level), Major.fromJson(json.vocational), json.code, json.is_active);
    }
}