<template>
    <div class="row g-2 align-items-center">
        <div class="col">
            <div class="page-pretitle">
                Daftar Semua Kelas
            </div>
            <h2 class="page-title">
                Daftar Kelas
            </h2>
        </div>
        <div class="col-12 col-md-auto ms-auto d-print-none">
            <div class="btn-list">
                <router-link :to="route.classRoom.form" class="btn btn-primary d-none d-sm-inline-block">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
                        stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <line x1="12" y1="5" x2="12" y2="19"></line>
                        <line x1="5" y1="12" x2="19" y2="12"></line>
                    </svg>
                    Tambah Kelas Baru
                </router-link>
                <a href="#" class="btn btn-primary d-sm-none btn-icon" data-bs-toggle="modal" data-bs-target="#modal-report"
                    aria-label="Create new report">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
                        stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <line x1="12" y1="5" x2="12" y2="19"></line>
                        <line x1="5" y1="12" x2="19" y2="12"></line>
                    </svg>
                </a>
            </div>
        </div>
    </div>
    <div class="card my-3">
        <div v-if="isLoading"><p class="text-center">Loading.....</p></div>
        <div v-else>
            <div v-if="data.length > 0">
                <TableComponent :onDelete="this.deleteClass" :onEdit="this.editClass" :onShowEntries="this.onShowEntriesChange()" :columns="this.fieldTable" :meta="this.meta" :dataType="ClassRoomModel" :data="this.dataTable"></TableComponent>
            </div>
            <div v-else>
            <NoDataFound :objectNotFound="notFoundTitle" :description="description"></NoDataFound>
            </div>
        </div>
    </div>

</template>

<script>
import route_name from '@/router/name'
import api from '@/packages/config/api';
import settings from '@/packages/config/settings';
import NoDataFound from '@/components/NoDataFound.vue';
import TableComponent from '@/components/TableComponent.vue';
import ClassRoomModel from './data/ClassRoom';
import Swal from 'sweetalert2'

export default {
    name: 'class-list',
    data() {
        return {
            route: route_name,
            fieldTable: [
                {
                    'key': 'number',
                    'label': "No",
                    'isSortable': true,
                    'isHtml': false,
                },
                {
                    'key': 'class',
                    'label': "Kelas",
                    'isSortable': false,
                    'isHtml': true,
                },
                {
                    'key': 'level',
                    'label': "Tingkat",
                    'isSortable': true,
                    'isHtml': false,
                },
                {
                    'key': 'vocational_code',
                    'label': "Kode Jurusan",
                    'isSortable': true,
                    'isHtml': false,
                },
                {
                    'key': 'status',
                    'label': "Status",
                    'isSortable': true,
                    'isHtml': true,
                },
                {
                    'key': 'action',
                    'label': "Action",
                    'isSortable': false,
                    'isHtml': false,
                    'class': 'text-nowrap',
                },
            ],
            
            isLoading: true,
            notFoundTitle: 'Student',
            description: 'Data student not found!, please check your data',
            meta: {},
            data: [],
            dataTable: [],
            showTable: 0,
        }
    },
    components: {
        NoDataFound,
        TableComponent,
    },
    methods: {
        async getAllClass() {
            try {
                const response = await api.GET(`${settings.CLASSROOM_URI}`);
                if (response.success) {
                    this.data = [];
                    response.content.forEach(element => {
                        let classRoom = ClassRoomModel.fromJson(element);
                        this.data.push(classRoom);
                    });
                }
            } catch (error) {
                console.log(`Catch Get All Class : ${error}`);
            }
            this.isLoading = false;
        },
        dataTableCreated() {
            this.dataTable = [];
            let num = 1;
            this.data.forEach(element => {
                let row = {
                    number: num++,
                    level: element.level.str,
                    class: element.code,
                    vocational_code: element.vocational.code,
                    params: element.code,
                    singleData: element,
                    status: `<span class="badge ${element.is_active ? 'bg-success' : 'bg-danger'} me-1"></span> ${element.is_active ? 'Aktif' : 'Tidak Aktif'}`
                }
                this.dataTable.push(row);
            });
        },
        onShowEntriesChange(val) {
            this.showTable = val;
            this.dataTableCreated();
        },
        async deleteClass(code) {
            try {
                console.log(code.params);
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then( async (result)  => {
                    if (result.isConfirmed) {
                        const response = await api.DELETE(`${settings.CLASSROOM_URI}/${code.params}`);
                        if (response.success) {
                            Swal.fire(
                                'Deleted!',
                                'Your file has been deleted.',
                                'success'
                            )
                            this.getAllClass();
                            this.dataTableCreated();
                        }
                    }
                });
                
            } catch (error) {
                console.log(`Catch Delete Academic Year : ${error}`);
            }
        },
        editClass(row) {
            this.$router.push({
                name: "add-classroom",
                params: { isEdit: true, data: JSON.stringify(row.data) },
            });
        }
    },
    created() {
        this.getAllClass()
    }
}
</script>