import { createRouter,createWebHistory } from 'vue-router'
import store from '@/store'
import route_name from '@/router/name'
import teacherRoutes from '@/modules/teachers/router'
import subjectRoute from '@/modules/subjects/router'
import studentRoute from '@/modules/students/router'
import classRoomRoute from '@/modules/classrooms/router'
import majorRoute from '@/modules/majors/router'
import academicYearRoute from '@/modules/academic-year/router'
import NotFound from '../components/NotFound.vue'
import DashboardView from '../modules/dashboard/DashboardView.vue'
import LoginComponent from '../modules/auth/LoginComponent.vue'

const routes = [
    {
        path: route_name.auth.internal,
        name: 'login-component',
        component: LoginComponent,

    },
    {
        path: route_name.dashboard,
        name: 'dashboard',
        component: DashboardView,
        meta: { requiresAuth: true },

    },
    ...teacherRoutes,
    ...subjectRoute,
    ...studentRoute,
    ...classRoomRoute,
    ...majorRoute,
    ...academicYearRoute,
    {
        path: route_name.notFound,
        name: '404',
        component: NotFound,
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.path)) {
        if (to.matched.some((record) => record.meta.requiresAuth)) {
            if (store.getters.isAuthenticated) {
                next();
                return;
            }
            next(route_name.auth.internal);
        
        } else {
          next();
        }
    } else {
      next(route_name.notFound);
    }
});

export default router