<template>
    <div class="row g-2 align-items-center">
        <div class="col">
            <div class="page-pretitle">
                Ubah Mata Pelajaran Baru
            </div>
            <h2 class="page-title">
                UBAH MATA PELAJARAN BARU
                
            </h2>
        </div>
    </div>
	<div class="card my-3">
        <div class="card-body border-bottom py-3">
            <div class="row">
                <div class="col-xl-6">
                    <div class="row">
                        <div class="col-md-6 col-xl-12">
                            <div class="mb-3">
                                <label class="form-label required" for="code">Kode Mata Pelajaran</label>
                                <input type="text" v-model.trim="code" class="form-control" name="code" id="code"
                                    placeholder="Kode Mata Pelajaran">
                            </div>
                            <div class="mb-3">
                                <label class="form-label required" for="name">Nama Mata Pelajaran</label>
                                <input type="text" v-model="name" class="form-control" name="name" id="name"
                                    placeholder="Nama Mata Pelajaran">
                            </div>
                        </div>
                    </div>
                </div>
				<div class="col-xl-6">
                    <div class="row">
                        <div class="col-md-6 col-xl-12">
                            <div class="mb-3">
                                <div class="form-label">Tingkat</div>
                                <select class="form-select" v-model.trim="level">
                                    <option value="">-- PILIH TINGKAT --</option>
                                    <option value="10">X</option>
                                    <option value="11">XI</option>
                                    <option value="12">XII</option>
                                </select>
                            </div>
							<div class="mb-3">
                                <div class="form-label">Status Mata Pelajaran</div>
                                <label class="form-check form-switch">
                                    <input class="form-check-input" v-model.trim="status" type="checkbox" name="global-status">
                                    <span class="form-check-label">Aktif</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
	<div class="card">
        <div class="card-body d-flex">
            <a href="#" v-on:click="this.updateSubject" class="btn ms-auto btn-primary w-10">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-device-floppy" width="24"
                    height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                    stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M6 4h10l4 4v10a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2"></path>
                    <circle cx="12" cy="14" r="2"></circle>
                    <polyline points="14 4 14 8 8 8 8 4"></polyline>
                </svg>
                Simpan
            </a>
        </div>
    </div>

</template>

<script>
import route_name from '@/router/name';
import api from '@/packages/config/api';
import settings from '@/packages/config/settings';
import Subject from './data/Subject';
export default {
    name: 'edit-subject',
    
    data() {
        return {
            route: route_name,
            params: '',
            level: '',
            subject: Object,
            error: false,
            message: '',
        }
    },
    methods: {
        async updateSubject() {
            try {
                const data = {
                    "code": this.code,
                    "level": this.level,
                    "name": this.name,
                    "is_active": this.status
                }
                const response = await api.PATCH(`${settings.SUBJECT_URI}/${this.subject.code}`, data);
                if (response.success) {
                    console.log(`Data berhasil di update ${this.source}`);
                    this.$router.go(-2)
                    
                }
            } catch (error) {
                console.log(`Catch Error Update Subject : ${error}`);
            }
        },
        async getDetailSubject() {
            try {
                const response = await api.GET(`${settings.SUBJECT_URI}/${this.params}`);
                if (response.success) {
                    this.subject = Subject.fromJson(response.content)
                    if (this.subject) {
                        this.code = this.subject.code
                        this.name = this.subject.name
                        this.level = this.subject.level
                        this.status = this.subject.is_active
                    } else {
                        this.error = true
                        this.message = response.message
                    }
                }
            } catch (error) {
                this.error = true
                this.message = error
                console.log(`Catch Error Get Detail Subject ${error}`)
            }
        }
    },
    async created() {
        console.log('CREATED')
        this.params = this.$route.params.code
        await this.getDetailSubject()
    },
    async mounted() {
        this.params = this.$route.params.code
        await this.getDetailSubject()
    }
}
</script>