<template>
    <div class="row g-2 align-items-center">
        <div class="col">
            <!-- Page pre-title -->
            <div class="page-pretitle">
                {{ this.title }}
            </div>
            <h2 class="page-title">
                {{ this.title.toUpperCase() }}
            </h2>
        </div>
    </div>
	<div class="card my-3">
        <div class="card-body border-bottom py-3">
            <div class="row">
                <div class="col-xl-6">
                    <div class="row">
                        <div class="col-md-6 col-xl-12">
                            <div class="mb-3">
                                <label class="form-label required" for="major-code">Kode Jurusan</label>
                                <input v-model.trim="majorCode" :disabled="this.isEdit" type="text" class="form-control" name="major-code" id="major-code"
                                    placeholder="Kode Jurusan">
                            </div>
                            <div class="mb-3">
                                <label class="form-label required" for="major-name">Nama Jurusan</label>
                                <input type="text" v-model="majorName" class="form-control" name="major-name" id="major-name"
                                    placeholder="Nama Jurusan">
                            </div>
                        </div>
                    </div>
                </div>
				<div class="col-xl-6">
                    <div class="row">
                        <div class="col-md-6 col-xl-12">
                            <div class="mb-3">
                                <div class="form-label">Custom File Input</div>
                                <input type="file" class="form-control" />
                            </div>
							<div class="mb-3">
                                <div class="form-label">Status Jurusan</div>
                                <label class="form-check form-switch">
                                    <input class="form-check-input" v-model="status" type="checkbox" name="major-status">
                                    <span class="form-check-label">Aktif</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
	<div class="card">
        <div class="card-body d-flex">
            <a href="#" v-on:click="this.methodMajor" class="btn ms-auto btn-primary w-10">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-device-floppy" width="24"
                    height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                    stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M6 4h10l4 4v10a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2"></path>
                    <circle cx="12" cy="14" r="2"></circle>
                    <polyline points="14 4 14 8 8 8 8 4"></polyline>
                </svg>
                Simpan
            </a>
        </div>
    </div>
</template>

<script>
import route_name from '@/router/name';
import api from '@/packages/config/api';
import settings from '@/packages/config/settings';
import MajorModel from './data/Major';

export default {
    name: 'form-major',
    data() {
        return {
            route: route_name,
            majorData: Object,
            title: "Tambah Jurusan Baru",
            error: false,
            message: '',
            params: '',
            status: false,
            source: '',
        }
    },
    props: {
        isEdit: {
            type: Boolean,
            default: false,
        },
        from: {
            type: String,
            default: '',
        }
    },
    methods: {
        async methodMajor() {
            if (!this.isEdit) {
                this.createMajor();
            } else {
                this.updateMajor();
            }
        },
        async createMajor() {
            try {
                const data = {
                    "code": this.majorCode,
                    "name": this.majorName,
                    "is_active": this.status
                }
                const response = await api.POST(`${settings.VOCATIONAL_URI}/`, data);
                if (response.data.success) {
                    console.log("Data berhasil di input");
                    this.$router.push(this.route.major.list);
                }
            } catch (error) {
                console.log(`Catch Error Create Major : ${error}`);
            }
        },
        async updateMajor() {
            try {
                const data = {
                    "name": this.majorName,
                    "is_active": this.status
                }
                console.log(data);
                const response = await api.PATCH(`${settings.VOCATIONAL_URI}/${this.majorData.code}`, data);
                if (response.success) {
                    console.log("Data berhasil di update");
                    if (this.source === '') {
                        this.$router.push(this.route.major.list);
                    } else {
                        this.$router.push({
                            name: 'detail-major',
                            params: {'code': this.majorCode}
                        });
                    }
                }
            } catch (error) {
                console.log(`Catch Error Update Major : ${error}`);
            }
        },
        async getDetailMajor() {
            try {
                const response = await api.GET(`${settings.VOCATIONAL_URI}/${this.params}`)
                if (response.success) {
                    this.majorData = MajorModel.fromJson(response.content)
                    if (this.majorData) {
                        this.majorCode = this.majorData.code
                        this.majorName = this.majorData.name
                        this.status = this.majorData.is_active
                    }
                }
            } catch (error) {
                console.log(`Catch Error Update Major : ${error}`)
            }
        }
    },
    async mounted() {
        if (this.isEdit) {
            this.title = "Ubah Jurusan"
            this.source = this.from
            this.params = this.$route.params.code

            await this.getDetailMajor()
        }
    },
    async created() {
        console.log(`EDIT : ${this.isEdit}`)
        if (this.isEdit) {
            this.source = this.from
            this.title = "Ubah Jurusan"
            this.params = this.$route.params.code

            await this.getDetailMajor()
        }
    }

}
</script>