// TODO: all settings initialized here

const API_URI = process.env.VUE_APP_API_URI;
const AUTH_URI = process.env.VUE_APP_API_URI_AUTH;
const API_URI_REFRESH_TOKEN = process.env.VUE_APP_API_URI_REFRESH_TOKEN;
const AUTH_USER_INTERNAL_URI = process.env.VUE_APP_API_URI_AUTH_USER_INTERNAL


// TODO: Student URI
const STUDENT_URI = process.env.VUE_APP_API_URI_STUDENT;
const STUDENT_URI_STATUS = process.env.VUE_APP_API_URI_STUDENT_STATUS;

// TODO: Academic Year URI
const ACADEMIC_YEAR_URI = process.env.VUE_APP_API_URI_ACADEMIC_YEAR;
const ACADEMIC_YEAR_UNAVAILABLE_URI = process.env.VUE_APP_API_URI_ACADEMIC_YEAR_UNAVAILABLE_DATE;

//TODO: ClassRoom URI
const CLASSROOM_URI = process.env.VUE_APP_API_URI_CLASSROOM;
//TODO: Vocational / Major
const VOCATIONAL_URI = process.env.VUE_APP_API_URI_VOCATIONAL;
//TODO: Subject
const SUBJECT_URI = process.env.VUE_APP_API_URI_SUBJECT;

const settings = {
    API_URI,
    AUTH_URI,
    API_URI_REFRESH_TOKEN,
    AUTH_USER_INTERNAL_URI,
    STUDENT_URI,
    STUDENT_URI_STATUS,
    ACADEMIC_YEAR_URI,
    ACADEMIC_YEAR_UNAVAILABLE_URI,
    CLASSROOM_URI,
    VOCATIONAL_URI,
    SUBJECT_URI
}

export default settings;