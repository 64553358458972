<template>
    <div class="col-md-6 col-lg-3 mt-3">
        <div class="card">
            <div class="card-body p-4 text-center">
            <span class="avatar avatar-xl mb-3 avatar-rounded" style="background-image: url(./static/avatars/000m.jpg)"></span>
            <h3 class="m-0 mb-1"><a href="#">Paweł Kuna</a></h3>
            <div class="text-muted">UI Designer</div>
            <div class="mt-3">
                <span class="badge bg-purple-lt">Owner</span>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "teacher-card"
}
</script>