import ListMajor from '../../majors/ListMajors.vue';
import Add from '../../majors/AddMajors.vue';
import Detail from '../../majors/DetailMajor.vue';
import MajorView from '../../majors/MajorView.vue';
import route_name from '@/router/name';

const majorRoute = [
    {
        path: route_name.major.path,
        name: 'major',
        component: MajorView,
        meta: { requiresAuth: true },
        children: [
            {
                path: route_name.major.list,
                name: 'list-major',
                component: ListMajor,
            },
            {
                path: route_name.major.add,
                name: 'add-major',
                component: Add,
            },
            {
                path: route_name.major.edit,
                name: 'edit-major',
                component: Add,
                props: route => ({ isEdit: route.params.isEdit, from: route.params.from })
            },
            {
                path: route_name.major.detail,
                name: 'detail-major',
                component: Detail,
            },
        ]
    }
]

export default majorRoute; 