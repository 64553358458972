export default class UnAvailableDate {
    constructor(dateStart, dateEnd) {
        this.dateStart = dateStart;
        this.dateEnd = dateEnd;
    }

    static fromJson(json) {
        return new UnAvailableDate(json.start_date, json.end_date);
    }
}
