<template>
    <div class="container-tight my-5">
       
        <form class="card card-md" @submit.prevent="logIn" method="get" autocomplete="off">
          <div class="card-body">
            <h2 class="card-title text-center mb-4">Login to your account</h2>
            <div class="card card-white mb-4" v-if="this.showError">
                <div class="card-body">
                <p class="text-danger">{{this.errorMessage}}</p>
                </div>
            </div>
            <div class="mb-3">
              <label class="form-label">Email address</label>
              <input type="email" v-model="form.username" class="form-control" placeholder="Enter email" autocomplete="off">
            </div>
            <div class="mb-2">
              <label class="form-label">
                Password
                <span class="form-label-description">
                  <a href="./forgot-password.html">I forgot password</a>
                </span>
              </label>
              <div class="input-group input-group-flat">
                <input type="password" v-model="form.password" class="form-control" placeholder="Password" autocomplete="off">
              </div>
            </div>
            <div class="form-footer">
            
              <button type="submit" class="btn btn-primary w-100" v-if="!this.loading">Sign in</button>
              <div class="btn btn-primary w-100" v-else>Loding.....</div>
               
            </div>
          </div>
         
        </form>
        <div class="text-center text-muted mt-3">
          Don't have account yet? <a href="./sign-up.html" tabindex="-1">Sign up</a>
        </div>
      </div>
</template>

<script>

import api from '@/packages/config/api';
import settings from '@/packages/config/settings';
import { mapActions } from 'vuex';

export default {
  name: 'LoginComponent',
  components: {
  },
  data() {
    return {
        form: {
            username: '',
            password: ''
        },
        showError: false,
        errorMessage: '',
        loading: false,
    }
  },
  methods: {
    ...mapActions(['LogIn', 'Token', "ClientID"]),
    async logIn() {
        const User = new FormData();
        User.append('username', this.form.username);
        User.append('password', this.form.password);

        this.loading = true;

        try {
            const response = await api.POST(settings.AUTH_USER_INTERNAL_URI, User);
            if (response.data.success) {
               this.LogIn({
                username: this.form.username,
                password: this.form.password,
               });
              this.Token(response.headers['auth-token']);
              this.ClientID(response.headers['client-id']);
              this.$router.push('/');
            }else{
              this.showError = true;
              this.errorMessage = 'username or password is incorrect';
            }
           
        } catch (error) {
            this.showError = true;
            this.errorMessage = 'username or password is incorrect';
        }

        this.loading = false;
    }
  },
  computed: {
  },
  mounted() {
  },
  created() {
   
  },
}
</script>