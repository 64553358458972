<template>
    <div class="card-body" style="padding: 10px 0px">
        <div class="row">
            <div class="col-sm-8">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Data Diri</h4>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12 text-center">
                                <div class="mb-3">
                                <span class="avatar avatar-xl avatar-rounded">{{this.placeholder_avatar.toUpperCase()}}</span>
                                </div>
                                <div class="card-title mb-1">{{this.student.full_name}}</div>
                                <div class="text-muted mb-1">{{this.student.nis}}</div>
                                <span class="badge bg-green-lt">{{this.student.status.name}}</span>
                            </div>
                        </div>
                        <div class="row">
                            <dt class="col-3">NISN : </dt>
                            <dd class="col-9 text-end">{{this.student.nisn == undefined ? '-' : this.student.nisn}}</dd>
                        </div>
                        <div class="row">
                            <dt class="col-3">Nama lengkap : </dt>
                            <dd class="col-9 text-end">{{this.student.full_name == undefined ? '-' : this.student.full_name}}</dd>
                        </div>
                        <div class="row">
                            <dt class="col-3">Tempat, Tanggal Lahir : </dt>
                            <dd class="col-9 text-end">{{this.student.place_of_birth == undefined ? '-' : this.student.place_of_birth}}, {{this.student.date_of_birth == undefined ? '-' : this.student.date_of_birth}}</dd>
                        </div>
                        <div class="row">
                            <dt class="col-3">Email : </dt>
                            <dd class="col-9 text-end">{{this.student.email_address == undefined ? '-' : this.student.email_address}}</dd>
                        </div>
                        <div class="row">
                            <dt class="col-3">Nomor telepon : </dt>
                            <dd class="col-9 text-end">{{this.student.phone_number == undefined ? '-' : this.student.phone_number}}</dd>
                        </div>
                        <div class="row">
                            <dt class="col-3">Jenis Kelamin : </dt>
                            <dd class="col-9 text-end">{{this.student.gender == undefined ? '-' : this.student.gender}}</dd>
                        </div>
                        <div class="row">
                            <dt class="col-3">Alamat : </dt>
                            <dd class="col-9 text-end">{{this.student.address == undefined ? '-' : this.student.address}}</dd>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="col-sm-">
                            <button @click="this.onEdit" class="btn btn-primary">Edit</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Data Kelas</h4>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <dt class="col-6">Tahun Ajaran : </dt>
                            <dd class="col-6 text-end">{{this.student.place_of_birth == undefined ? '-' : this.student.place_of_birth}}, {{this.student.date_of_birth == undefined ? '-' : this.student.date_of_birth}}</dd>
                        </div>
                        <div class="row">
                            <dt class="col-6">Kelas : </dt>
                            <dd class="col-6 text-end">{{this.student.place_of_birth == undefined ? '-' : this.student.place_of_birth}}, {{this.student.date_of_birth == undefined ? '-' : this.student.date_of_birth}}</dd>
                        </div>
                        <div class="row">
                            <dt class="col-6">Status : </dt>
                            <dd class="col-6 text-end">{{this.student.status.name}}</dd>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="col-sm-">
                            <button @click="this.onEdit" class="btn btn-primary">Edit</button>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
import route_name from '@/router/name';
export default {
    name: "view-detail-student",
    data(){
        return {
            data: this.$props.student,
            placeholder_avatar: '',
        }
    },
    props: {
        student: {
            type: Object,
            default: () => {
                return {};
            }
        },
    
    },
    methods: {
        onEdit(){
            this.$router.push({
                name: route_name.student.student_edit.name,
                params: {
                    uid: this.data.uid,
                },
            });
        },
        createdPlaceHolderAvatar() {
            if (this.student.first_name !== undefined) {
                this.placeholder_avatar = `${this.student.first_name.substring(0,1)}${this.student.middle_name.substring(0,1)}${this.student.last_name.substring(0,1)}`;
            }
        }
    },
    created() {
       this.createdPlaceHolderAvatar();
    },

}
</script>