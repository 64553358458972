import ListTeachers from '../../teachers/ListTeachers.vue';
import AbsensiTeacher from '../../teachers/AbsensiTeacher.vue';
import AddTeacher from '../../teachers/AddTeacher.vue';
import route_name from '@/router/name'

const teacherRoutes = [
    // {
    //     path: route_name.teacher.path,
    //     name: 'teachers',
    //     component: TeacherView,
    //     meta: { requiresAuth: true },
    // },
    {
        path: route_name.teacher.path,
        name: 'list-teacher',
        component: ListTeachers,
        meta: { requiresAuth: true },
    },
    {
        path: route_name.teacher.absensi,
        name: 'absensi-teacher',
        component: AbsensiTeacher,
    },
    {
        path: route_name.teacher.add.path,
        name: route_name.teacher.add.name,
        component: AddTeacher,
    }
]

export default teacherRoutes; 