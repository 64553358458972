<template>
    <div class="row">
        <div class="col-md-12 col-lg-12">
            <div class="card-tabs ">
                <!-- Cards navigation -->
                <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item" role="presentation"><a href="#tab-top-1" class="nav-link active" data-bs-toggle="tab" aria-selected="true" role="tab">Data Diri Siswa</a></li>
                <li class="nav-item" role="presentation"><a href="#tab-top-2" class="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab" tabindex="-1">Data Absensi</a></li>
                <li class="nav-item" role="presentation"><a href="#tab-top-3" class="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab" tabindex="-1">Jadwal Pelajaran</a></li>
                <li class="nav-item" role="presentation"><a href="#tab-top-4" class="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab" tabindex="-1">Data orang tua</a></li>
                </ul>
                <div class="tab-content">
                <!-- Content of card #1 -->
                <div id="tab-top-1" class="tab-pane active show" role="tabpanel">
                    <div v-if="this.isLoading">
                        <p>Loading.......</p>
                    </div>
                    <div v-else>
                        <ViewDetailStudent :student="this.student"></ViewDetailStudent>
                    </div>
                    <!-- <div class="card-body">
                        <div class="row justify-content-center">
                            <div class="col-md-12 col-lg-12">
                                <div class="card-body p-4 text-center">
                                    <span class="avatar avatar-xl mb-3 avatar-rounded">{{this.placeholder_avatar.toUpperCase()}}</span>
                                        <h3 class="m-0 mb-1"><a href="#">{{this.student.full_name}}</a></h3>
                                        <div class="text-muted">NIS : {{this.student.nis == undefined ? '-' : this.student.nis}}</div>
                                        <div class="mt-3">
                                        <span class="badge bg-green-lt">{{this.student.status.name}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                               <li class="list-group-item d-flex justify-content-between align-items-center">
                                    <div class="ms-2 me-auto">
                                    <div class="fw-bold">NIS</div>
                                        {{this.student.nis == undefined ? '-' : this.student.nis}}
                                    </div>
                                    <span class="badge bg-white">
                                        <form @submit.prevent="onSubmitForm" v-if="this.form.nis.active">
                                            <div class="input-group">
                                                <input type="text" v-model="this.form.nis.value" class="form-control text-dark" placeholder="NIS">
                                                <button type="submit" class="btn text-dark">simpan</button>
                                            </div>
                                        </form>
                                       <button class="btn btn-icon btn-primary" v-else @click="this.onActiveForm('nis')">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-edit" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="#fcbe03" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1"></path>
                                                <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z"></path>
                                                <path d="M16 5l3 3"></path>
                                            </svg>
                                       </button>
                                    </span>
                                </li>
                               <li class="list-group-item d-flex justify-content-between align-items-center">
                                    <div class="ms-2 me-auto">
                                    <div class="fw-bold">NISN</div>
                                        {{this.student.nisn == undefined ? '-' : this.student.nisn}}
                                    </div>
                                     <span class="badge bg-white">
                                         <form @submit.prevent="onSubmitForm" v-if="this.form.nisn.active">
                                        <div class="input-group">
                                            <input type="text" v-model="this.form.nisn.value" class="form-control text-dark" placeholder="NISN">
                                            <button type="submit" class="btn text-dark">simpan</button>
                                        </div>
                                        </form>
                                       <button class="btn btn-icon btn-primary" v-else @click="this.onActiveForm('nisn')">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-edit" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="#fcbe03" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1"></path>
                                                <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z"></path>
                                                <path d="M16 5l3 3"></path>
                                            </svg>
                                       </button>
                                    </span>
                                </li>
                               <li class="list-group-item d-flex justify-content-between align-items-center">
                                    <div class="ms-2 me-auto">
                                    <div class="fw-bold">Kelas</div>
                                        -
                                    </div>
                                     <span class="badge bg-white">
                                         <form @submit.prevent="assignToClassRoom" v-if="this.form.classroom.active">
                                        <div class="input-group">
                                             <select class="form-select text-dark" v-model="this.form.classroom.value">
                                                <option v-for="row in this.classRooms" v-bind:key="row" :value="row.code">{{row.code}}</option>
                                            </select>
                                            <button type="submit" class="btn text-dark">simpan</button>
                                        </div>
                                        </form>
                                       <button class="btn btn-icon btn-primary" v-else @click="this.onActiveForm('classroom')">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-edit" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="#fcbe03" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1"></path>
                                                <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z"></path>
                                                <path d="M16 5l3 3"></path>
                                            </svg>
                                       </button>
                                    </span>
                                </li>
                               <li class="list-group-item d-flex justify-content-between align-items-center">
                                    <div class="ms-2 me-auto">
                                    <div class="fw-bold">Jenis Kelamin</div>
                                        {{this.student.gender == undefined ? '-' : this.student.gender}}
                                    </div>
                                     <span class="badge bg-white">
                                        <form @submit.prevent="onSubmitForm" v-if="this.form.gender.active">
                                        <div class="input-group">
                                            <div class="form-selectgroup">
                                                <label class="form-selectgroup-item" style="margin: 0px">
                                                    <input type="radio" v-model="this.form.gender.value" name="gender" value="laki-laki" class="form-selectgroup-input"
                                                        checked="">
                                                    <span class="form-selectgroup-label" style="border-radius: 4px 0px 0px 4px">
                                                        Laki-laki
                                                    </span>
                                                </label>
                                                <label class="form-selectgroup-item">
                                                    <input type="radio" v-model="this.form.gender.value" name="gender" value="perempuan" class="form-selectgroup-input">
                                                    <span class="form-selectgroup-label" style="border-radius: 0px">
                                                        Perempuan
                                                    </span>
                                                </label>
                                            </div>
                                           
                                            <button type="submit" class="btn text-dark">simpan</button>
                                        </div>
                                        </form>
                                       <button class="btn btn-icon btn-primary" v-else @click="this.onActiveForm('gender')">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-edit" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="#fcbe03" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1"></path>
                                                <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z"></path>
                                                <path d="M16 5l3 3"></path>
                                            </svg>
                                       </button>
                                    </span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                   <div class="ms-2 me-auto">
                                    <div class="fw-bold">Tanggal Lahir</div>
                                        {{this.student.date_of_birth == undefined ? '-' : this.student.date_of_birth}}
                                    </div>
                                     <span class="badge bg-white">
                                        <form @submit.prevent="onSubmitForm" v-if="this.form.date_of_birth.active">
                                        <div class="input-group" >
                                            <input type="date" v-model="this.form.date_of_birth.value"  :max="maxDate" class="form-control text-dark" placeholder="Tanggal Lahir">
                                            <button type="submit" class="btn text-dark">simpan</button>
                                        </div>
                                        </form>
                                       <button class="btn btn-icon btn-primary" v-else @click="this.onActiveForm('date_of_birth')">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-edit" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="#fcbe03" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1"></path>
                                                <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z"></path>
                                                <path d="M16 5l3 3"></path>
                                            </svg>
                                       </button>
                                    </span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                    <div class="ms-2 me-auto">
                                    <div class="fw-bold">Tempat Lahir</div>
                                        {{this.student.place_of_birth == undefined ? '-' : this.student.place_of_birth}}
                                    </div>
                                     <span class="badge bg-white">
                                         <form @submit.prevent="onSubmitForm" v-if="this.form.place_of_birth.active">
                                        <div class="input-group">
                                            <input type="text" v-model="this.form.place_of_birth.value" class="form-control text-dark" placeholder="Tempat Lahir">
                                            <button type="submit" class="btn text-dark">simpan</button>
                                        </div>
                                        </form>
                                       <button class="btn btn-icon btn-primary" v-else @click="this.onActiveForm('place_of_birth')">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-edit" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="#fcbe03" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1"></path>
                                                <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z"></path>
                                                <path d="M16 5l3 3"></path>
                                            </svg>
                                       </button>
                                    </span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                    <div class="ms-2 me-auto">
                                    <div class="fw-bold">Email</div>
                                        {{this.student.email_address == undefined ? '-' : this.student.email_address}}
                                    </div>
                                     <span class="badge bg-white">
                                         <form @submit.prevent="onSubmitForm" v-if="this.form.email.active">
                                        <div class="input-group" v-if="this.form.email.active">
                                            <input type="email" v-model="this.form.email.value" class="form-control text-dark" placeholder="Alamat Email">
                                            <button type="submit" class="btn text-dark">simpan</button>
                                        </div>
                                        </form>
                                       <button class="btn btn-icon btn-primary" v-else @click="this.onActiveForm('email')">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-edit" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="#fcbe03" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1"></path>
                                                <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z"></path>
                                                <path d="M16 5l3 3"></path>
                                            </svg>
                                       </button>
                                    </span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                    <div class="ms-2 me-auto">
                                    <div class="fw-bold">Nomor Telepon</div>
                                        {{this.student.phone_number == undefined ? '-' : this.student.phone_number}}
                                    </div>
                                     <span class="badge bg-white">
                                            <form @submit.prevent="onSubmitForm" v-if="this.form.phone_number.active">
                                        <div class="input-group" v-if="this.form.phone_number.active">
                                            <input type="text" v-model="this.form.phone_number.value" class="form-control text-dark" placeholder="Nomor Telepon">
                                            <button type="submit" class="btn text-dark">simpan</button>
                                        </div>
                                        </form>
                                       <button class="btn btn-icon btn-primary" v-else @click="this.onActiveForm('phone_number')">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-edit" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="#fcbe03" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1"></path>
                                                <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z"></path>
                                                <path d="M16 5l3 3"></path>
                                            </svg>
                                       </button>
                                    </span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                    <div class="ms-2 me-auto">
                                    <div class="fw-bold">Alamat</div>
                                        {{this.student.address == undefined ? '-' : this.student.address}}
                                    </div>
                                     <span class="badge bg-white">
                                            <form @submit.prevent="onSubmitForm" v-if="this.form.address.active">
                                        <div class="input-group">
                                            <input type="text" v-model="this.form.address.value" class="form-control text-dark" placeholder="Alamat">
                                            <button type="submit" class="btn text-dark">simpan</button>
                                        </div>
                                        </form>
                                       <button class="btn btn-icon btn-primary" v-else @click="this.onActiveForm('address')">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-edit" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="#fcbe03" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1"></path>
                                                <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z"></path>
                                                <path d="M16 5l3 3"></path>
                                            </svg>
                                       </button>
                                    </span>
                                </li>
                            </div>
                        </div>
                    </div> -->
                </div>
                <!-- Content of card #2 -->
                <div id="tab-top-2" class="card tab-pane" role="tabpanel">
                    <div class="card-body">
                    <div class="card-title">Content of tab #2</div>
                    <p class="text-muted">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci, alias aliquid distinctio dolorem expedita, fugiat hic magni molestiae molestias odit.
                    </p>
                    </div>
                </div>
                <!-- Content of card #3 -->
                <div id="tab-top-3" class="card tab-pane" role="tabpanel">
                    <div class="card-body">
                    <div class="card-title">Content of tab #3</div>
                    <p class="text-muted">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci, alias aliquid distinctio dolorem expedita, fugiat hic magni molestiae molestias odit.
                    </p>
                    </div>
                </div>
                <!-- Content of card #4 -->
                <div id="tab-top-4" class="card tab-pane" role="tabpanel">
                    <div class="card-body">
                    <div class="card-title">Content of tab #4</div>
                    <p class="text-muted">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci, alias aliquid distinctio dolorem expedita, fugiat hic magni molestiae molestias odit.
                    </p>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/packages/config/api';
import settings from '@/packages/config/settings';
import StudentModel from './data/Students';
import ClassRoomModel from '../classrooms/data/ClassRoom';
import ViewDetailStudent from './component/ViewDetailStudent.vue';
export default {
    name: 'detail-student',
    components: {
        ViewDetailStudent
    },
    data() {
        const max = new Date(new Date().getFullYear() - 3, new Date().getMonth(), new Date().getDate())
        return {
            isLoading: true,
            uid: this.$router.currentRoute.value.params.uid,
            student: {},
            placeholder_avatar: '',
            form: {
                nis: {
                    placeholder: 'NIS',
                    value: '',
                    active: false,
                },
                nisn: {
                    placeholder: 'NISN',
                    value: '',
                    active: false,
                },
                classroom: {
                    placeholder: 'kelas',
                    value: '',
                    active: false,
                },
                phone_number: {
                    placeholder: 'Nomor Telepon',
                    value: '',
                    active: false,
                },
                address: {
                    placeholder: 'Alamat',
                    value: '',
                    active: false,
                },
                date_of_birth: {
                    placeholder: 'Tanggal Lahir',
                    value: '',
                    active: false,
                },
                email: {
                    email: 'Email',
                    value: '',
                    active: false,
                },
                place_of_birth: {
                    placeholder: 'Tempat Lahir',
                    value: '',
                    active: false,
                },
                gender: {
                    placeholder: 'Jenis Kelamin',
                    value: '',
                    active: false,
                },
                maxDate: max,   
            },
            classRooms: [],
        }
    },
    methods: {
        async onSubmitForm(){
           
            const form = {
                first_name: this.student.first_name,
                last_name: this.student.last_name,
                middle_name: this.student.middle_name,
                nis: this.form.nis.value,
                nisn: this.form.nisn.value,
                phone_number: this.form.phone_number.value,
                address: this.form.address.value,
                date_of_birth: this.form.date_of_birth.value === '' ? null : this.form.date_of_birth.value,
                email_address: this.form.email.value,
                place_of_birth: this.form.place_of_birth.value,
                status: this.student.status.slug,
            }
            this.form.nis.active = false;
            this.form.nisn.active = false;
            this.form.phone_number.active = false;
            this.form.address.active = false;
            this.form.date_of_birth.active = false;
            this.form.email.active = false;
            this.form.place_of_birth.active = false;
            this.form.classroom.active = false;
            await api.PATCH(`${settings.STUDENT_URI}${this.uid}`, StudentModel.toJson(form));
           
            await this.getStudent();
        },
        onActiveForm(model_name){
            this.form[model_name].active = true;
        },
        async getStudent() {
            
            const response = await api.GET(`${settings.STUDENT_URI}${this.uid}`);
            if(response.success) {
                console.log(response.content);
                this.student = StudentModel.fromJson(response.content);
                this.placeholder_avatar = `${this.student.first_name.substring(0,1)}${this.student.middle_name.substring(0,1)}${this.student.last_name.substring(0,1)}`;
                this.form = {
                    nis: {
                        placeholder: 'NIS',
                        value: this.student.nis == undefined ? '' : this.student.nis,
                        active: false,
                    },
                    nisn: {
                        placeholder: 'NISN',
                        value: this.student.nisn == undefined ? '' : this.student.nisn,
                        active: false,
                    },
                    classroom: {
                        placeholder: 'kelas',
                        value: '',
                        active: false,
                    },
                    phone_number: {
                        placeholder: 'Nomor Telepon',
                        value: this.student.phone_number == undefined ? '' : this.student.phone_number,
                        active: false,
                    },
                    address: {
                        placeholder: 'Alamat',
                        value: this.student.address == undefined ? '' : this.student.address,
                        active: false,
                    },
                    date_of_birth: {
                        placeholder: 'Tanggal Lahir',
                        value: this.student.date_of_birth == undefined ? '' : this.student.date_of_birth,
                        active: false,
                    },
                    email: {
                        email: 'Email',
                        value: this.student.email_address == undefined ? '' : this.student.email_address,
                        active: false,
                    },
                    place_of_birth: {
                        placeholder: 'Tempat Lahir',
                        value: this.student.place_of_birth == undefined ? '' : this.student.place_of_birth,
                        active: false,
                    },
                    gender: {
                        placeholder: 'Jenis Kelamin',
                        value: this.student.gender == undefined ? '' : this.student.gender,
                        active: false,
                    },
                }

                
            }

            this.isLoading = false;

        },
        getAbsensi() {
            // this.$store.dispatch('getAbsensi', this.uid);
        },
        getJadwal() {
            // this.$store.dispatch('getJadwal', this.uid);
        },
        getParent() {
            // this.$store.dispatch('getOrangTua', this.uid);
        },
        async getClassRoom(){
            const response = await api.GET(`${settings.CLASSROOM_URI}`);
            if (response.success) {
                this.classroom = [];
                console.log(response.content.data);
                response.content.data.forEach(element => {
                    let classRoom = ClassRoomModel.fromJson(element);
                    if(classRoom.is_active){
                        this.classRooms.push(classRoom);
                    }
                    
                });
            }
        },
        async assignToClassRoom(){
            const response = await api.POST(`${settings.CLASSROOM_URI}/${this.form.classroom.value}/student`, {
                uid: this.uid,
            });
            this.form.classroom.active = false;
            if (response.success) {
                this.getStudent();
            }
        
        }
    },
    created() {
        this.getStudent();
        this.getClassRoom();
    },
}
</script>