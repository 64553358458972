<template>
    <div class="row g-2 align-items-center">
        <div class="col">
            <!-- Page pre-title -->
            <div class="page-pretitle">
                Detail Jurusan
            </div>
            
        </div>
    </div>
    <div class="card my-3">
        <div class="card-header">
            <div v-if="this.error" class="col-12 row align-items-center text-center">
                <h2 class="page-title d-block">
                    Kesalahan
                </h2>
                <h2 class="page-pretitle">
                    {{ this.message }}
                </h2>
            </div>
            <div v-else class="col-12 row d-flex align-items-center">
                <div class="col-auto">
                    <h2 class="page-title">
                        DETAIL JURUSAN
                    </h2>
                </div>
                <div class="col-auto ms-auto">
                    <router-link :to="{name: 'edit-major', params: {'isEdit': true, 'from': 'detail', 'code': `${this.majorData.code}` }}" class="btn btn-outline-warning d-none d-sm-inline-block me-3">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon m-0 icon-tabler icon-tabler-edit" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="#fcbe03" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1"></path>
                            <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z"></path>
                            <path d="M16 5l3 3"></path>
                        </svg>
                        Ubah
                    </router-link>
                    <button v-on:click="this.onDeleteMajor" class="btn btn-danger d-none d-sm-inline-block">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon m-0" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <line x1="4" y1="7" x2="20" y2="7"></line>
                            <line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line>
                            <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                            <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
                        </svg>
                        Hapus
                    </button>
                </div>
            </div>
        </div>
        <div v-if="!this.error" class="card-body border-bottom py-3">
            <div class="row row-0">
                <div class="col-xl-3">
                    <img src="@/assets/006f.jpg" class="w-75 h-100 object-cover ms-3" alt="Card side image">
                </div>
				<div class="col-xl-6 ms-3 mt-4">
                    <div class="row">
                        <div class="col-md-6 col-xl-12">
                            <div class="mb-3">
                                Kode Jurusan
                                <div class="form-label">{{ this.majorData.code }}</div>
                            </div>
                            <div class="mb-3">
                                <div class="form-label">Nama Jurusan</div>
                                {{ this.majorData.name }}
                            </div>
							<div class="mb-3">
                                <div class="form-label">Status Mata Pelajaran</div>
                                <span class="badge me-1" :class="this.majorData.is_active ? 'bg-success' : 'bg-danger'"></span> {{ this.majorData.is_active ? 'Aktif' : 'Tidak Aktif'}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import route_name from '@/router/name';
import api from '@/packages/config/api';
import settings from '@/packages/config/settings';
import MajorModel from './data/Major';
import Swal from 'sweetalert2'
export default {
    name: 'detail-major',
    data() {
        return {
            route: route_name,
            majorData: MajorModel,
            code: '',
            error: false,
            message: '',
        }
    },
    methods: {
        async getDetailMajor() {
            try {
                const response = await api.GET(`${settings.VOCATIONAL_URI}/${this.code}`)
                if (response.success) {
                    this.majorData = MajorModel.fromJson(response.content)
                    console.log(`Data : ${this.majorData}`)
                } else {
                    this.error = true
                    this.message = response.message
                }
            } catch (error) {
                this.error = true
                this.message = error
                console.log(`Catch Detail Major : ${error}`)
            }
        },
        async onDeleteMajor() {
            try {
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then( async (result)  => {
                    if (result.isConfirmed) {
                        const response = await api.DELETE(`${settings.VOCATIONAL_URI}/${this.majorData.code}`)
                        if (response.success) {
                            Swal.fire(
                                'Deleted!',
                                'Your file has been deleted.',
                                'success'
                            )
                            this.$router.push(this.route.major.list);
                        }
                    }
                });
                
            } catch (error) {
                console.log(`Catch Delete Major : ${error}`);
            }
        },
    },
    async created() {
        console.log(this.$route.params)
        this.code = this.$route.params.code
        await this.getDetailMajor()        
    },
}
</script>