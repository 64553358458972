import ListClass from '../../classrooms/ListClass.vue';
import Add from '../../classrooms/AddClass.vue';
import ClassView from '../../classrooms/ClassroomView.vue';
import route_name from '@/router/name';

const classRoute = [
    {
        path: route_name.classRoom.path,
        name: 'classroom',
        component: ClassView,
        meta: { requiresAuth: true },
        children: [
            {
                path: route_name.classRoom.list,
                name: 'list-classroom',
                component: ListClass,
            },
            {
                path: route_name.classRoom.form,
                name: 'add-classroom',
                component: Add,
            },
        ]
    }
]

export default classRoute; 