<template>
    <BlankFound></BlankFound>
</template>

<script>
import BlankFound from '../../components/BlankFound.vue'
export default {
  name: 'Absensi-Teacher',
  components: {
    BlankFound,
  }
}
</script>