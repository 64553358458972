import StatusStudent from "./Status";

export default class Student {
    first_name = "";
    full_name = ""
    last_name = ""
    middle_name = "";
    nis = "";
    nisn = "";
    status = StatusStudent;
    uid = '';
    gender = '';
    date_of_birth = '';
    place_of_birth = '';
    address = '';
    phone_number = '';
    email_address = '';
    status = '';

    constructor(first_name, full_name, last_name, middle_name, nis, nisn, status, uid, gender, date_of_birth, place_of_birth, address, phone_number, email_address) {
        this.first_name = first_name;
        this.full_name = full_name;
        this.last_name = last_name;
        this.middle_name = middle_name;
        this.nis = nis;
        this.nisn = nisn;
        this.status = status;
        this.uid = uid;
        this.gender = gender;
        this.date_of_birth = date_of_birth;
        this.place_of_birth = place_of_birth;
        this.address = address;
        this.phone_number = phone_number;
        this.email_address = email_address;
        
    }

    static fromJson(json) {
        return new Student(json.first_name, json.full_name, json.last_name, json.middle_name, json.nis, json.nisn, StatusStudent.fromJson(json.status), json.uid);
    }

    static toJson(json) {
        const date = new Date(json.date_of_birth);
        const Student =  new FormData();
        Student.append('nis', json.nis);
        Student.append('nisn', json.nisn);
        Student.append('first_name', json.first_name);
        Student.append('middle_name', json.middle_name);
        Student.append('last_name', json.last_name);
        Student.append('gender', json.gender);
        Student.append('date_of_birth', json.date_of_birth != null ? `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}` : null);
        Student.append('place_of_birth', json.place_of_birth);
        Student.append('address', json.address);
        Student.append('phone_number', json.phone_number);
        Student.append('email_address', json.email_address);
        Student.append('status', json.status);
        return Student;
    }
}
