<template>
    <div class="container-xl d-flex flex-column justify-content-center">
            <div class="empty">
              <div class="empty-img"><img src="<div></div>" height="128" alt="">
              </div>
              <p class="empty-title">No results found ({{this.$route.name}})</p>
              <p class="empty-subtitle text-muted">
                Try adjusting your search or filter to find what you're looking for.
              </p>
            </div>
          </div>
</template>



