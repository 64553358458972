<template>
    <div class="container-xl d-flex flex-column justify-content-center">
        <div class="empty">
            <p class="empty-title">This is {{this.$route.name}}</p>
            <div class="empty-action">
            <a href="/" class="btn btn-primary">
                To Dashboard
            </a>
            </div>
        </div>
    </div>
</template>