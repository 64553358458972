<template>
    <form @submit.prevent="submit">
    <div class="row g-2 align-items-center">
        <div class="col">
            <!-- Page pre-title -->
            <div class="page-pretitle">
                Create New Student
            </div>
            <h2 class="page-title">
                ADD NEW STUDENT AND PARENT
            </h2>
        </div>
    </div>
    <div class="card my-3">
        <div class="card-header d-flex">
            <span class="align-items-center">Data Student</span>
            <!-- <a href="{{ route('student.create') }}" class="btn ms-auto btn-dribbble w-10">
            
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-plus" width="24"
                    height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                    stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <circle cx="12" cy="12" r="9"></circle>
                    <line x1="9" y1="12" x2="15" y2="12"></line>
                    <line x1="12" y1="9" x2="12" y2="15"></line>
                </svg>
                Add New Student
            </a> -->
        </div>
        <div class="card-body border-bottom py-3">

            <div class="row">
                <div class="col-xl-6">
                    <div class="row">
                        <div class="col-md-6 col-xl-12">
                           
                            <div class="mb-3">
                                <label class="form-label required" for="nis">NIS</label>
                                <input type="text" class="form-control" v-model="v$.form.nis.$model" name="nis" id="nis"
                                    placeholder="NIS">
                               <div class="input-errors" v-for="(error, index) in v$.form.nis.$errors" v-bind:key="index">
                                    <div class="text-danger">{{ error.$message }}</div>
                                </div>
                            </div>
                            <div class="mb-3">
                                <label class="form-label required" for="nisn">NISN</label>
                                <input type="text" class="form-control" v-model="v$.form.nisn.$model" name="nisn" id="nisn"
                                    placeholder="NISN">
                                    <div class="input-errors" v-for="(error, index) in v$.form.nisn.$errors" v-bind:key="index">
                                    <div class="text-danger">{{ error.$message }}</div>
                                </div>
                            </div>
                             <div class="mb-3">
                                <label class="form-label required" for="first-name">First Name</label>
                                <input type="text" class="form-control" v-model="v$.form.first_name.$model" name="first-name" id="first-name"
                                    placeholder="First Name...">
                                <div class="input-errors" v-for="(error, index) in v$.form.first_name.$errors" v-bind:key="index">
                                    <div class="text-danger">{{ error.$message }}</div>
                                </div>
                            </div>
                            <div class="mb-3">
                                <label class="form-label" for="middle-name">Middle Name</label>
                                <input type="text" class="form-control" v-model="form.middle_name" name="middle-name" id="middle-name"
                                    placeholder="Middle Name...">
                                    
                            </div>
                            <div class="mb-3">
                                <label class="form-label" for="last-name">Last Name</label>
                                <input type="text" class="form-control" v-model="form.last_name" name="last-name" id="last-name"
                                    placeholder="Last Name...">
                            </div>
                            <div class="mb-3">
                                <label class="form-label required">Gender</label>
                                <div class="form-selectgroup">
                                    <label class="form-selectgroup-item">
                                        <input type="radio" v-model="form.gender" name="gender" value="laki-laki" class="form-selectgroup-input"
                                            checked="">
                                        <span class="form-selectgroup-label">
                                            Laki-laki
                                        </span>
                                    </label>
                                    <label class="form-selectgroup-item">
                                        <input type="radio" v-model="form.gender" name="gender" value="perempuan" class="form-selectgroup-input">
                                        <span class="form-selectgroup-label">
                                            Perempuan
                                        </span>
                                    </label>
                                </div>
                            </div>

                           
                             
                        </div>
                    </div>
                </div>
                <div class="col-xl-6">
                    <div class="row">
                        <div class="col-md-6 col-xl-12">
                           <div class="mb-3">
                                <label class="form-label required">Date of birth</label>
                                <input class="form-control mb-2" type="date" placeholder="Select a date" :max="maxDate" id="datepicker-default" v-model="v$.form.date_of_birth.$model">
                                <div class="input-errors" v-for="(error, index) in v$.form.date_of_birth.$errors" v-bind:key="index">
                                    <div class="text-danger">{{ error.$message }}</div>
                                </div>
                            </div>
                            
                            <div class="mb-3">
                                <label class="form-label required">Place of birth</label>
                                <input type="text" class="form-control" v-model="v$.form.place_of_birth.$model" name="place-of-birth" id="place-of-birth"
                                    placeholder="Place of birth">
                                <div class="input-errors" v-for="(error, index) in v$.form.place_of_birth.$errors" v-bind:key="index">
                                    <div class="text-danger">{{ error.$message }}</div>
                                </div>    
                            </div>
                           
                            <div class="mb-3">
                                <label class="form-label required">Phone number</label>
                                <input type="text" name="phone-number" v-model="v$.form.phone_number.$model" class="form-control" data-mask="(00) 0000-0000"
                                    data-mask-visible="true" placeholder="(+62) 0000-0000" autocomplete="off">
                                <div class="input-errors" v-for="(error, index) in v$.form.phone_number.$errors" v-bind:key="index">
                                    <div class="text-danger">{{ error.$message }}</div>
                                </div>    
                            </div>
                            <div class="mb-3">
                                <label class="form-label required" for="email-address">Email Address</label>
                                <input type="text" class="form-control" v-model="v$.form.email_address.$model" name="email-address" id="email-address"
                                    placeholder="First Name...">
                                    <div class="input-errors" v-for="(error, index) in v$.form.email_address.$errors" v-bind:key="index">
                                    <div class="text-danger">{{ error.$message }}</div>
                                </div>   
                            </div>
                             <div class="mb-3">
                                <label class="form-label required">Address <span
                                        class="form-label-description">56/100</span></label>
                                <textarea class="form-control" v-model="v$.form.address.$model" name="address" rows="6" placeholder="Address.."></textarea>
                                <div class="input-errors" v-for="(error, index) in v$.form.address.$errors" v-bind:key="index">
                                    <div class="text-danger">{{ error.$message }}</div>
                                </div>   
                            </div>
                            <div class="mb-3">
                                <div class="form-label">Status Student</div>
                                <select class="form-select" v-model="form.status">
                                    <option v-for="status in this.statuses" v-bind:key="status" :value="status.slug">{{status.name}}</option>
                              </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="card-footer d-flex align-items-center">
            <span>Footer</span>
        </div> -->
    </div>
    <div class="card">
        <div class="card-body d-flex">
            <span class="d-none d-sm-inline">

                <a href="" class="btn btn-white w-10">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-square-x" width="24"
                        height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                        stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <rect x="4" y="4" width="16" height="16" rx="2"></rect>
                        <path d="M10 10l4 4m0 -4l-4 4"></path>
                    </svg>
                    Batal
                </a>
            </span>
            <button type="submit" class="btn ms-auto btn-primary w-10">
                <!-- Download SVG icon from http://tabler-icons.io/i/brand-dribbble -->
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-device-floppy" width="24"
                    height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                    stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M6 4h10l4 4v10a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2"></path>
                    <circle cx="12" cy="14" r="2"></circle>
                    <polyline points="14 4 14 8 8 8 8 4"></polyline>
                </svg>
                Simpan
            </button>
        </div>
    </div>
    </form>
</template>

<script>
import '@tabler/core/dist/libs/litepicker/dist/litepicker'
import api from '@/packages/config/api';
import settings from '@/packages/config/settings';
import StudentModel from './data/Students';
import Status from './data/Status';
import route_name from '@/router/name';
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
export default {
    setup () {
        return { v$: useVuelidate() }
    },
    name: 'add-student',
    data() {
        const max = new Date(new Date().getFullYear() - 3, new Date().getMonth(), new Date().getDate())
        return {
            loading: false,
            statuses: [],
            form: {
                nisn: null,
                nis: null,
                first_name: null,
                middle_name: null,
                last_name: null,
                gender: 'laki-laki',
                date_of_birth: null,
                place_of_birth: null,
                address: null,
                phone_number: null,
                email_address: null,
                status: null,
            },
            maxDate: max,
        }
    },
    validations(){
        return{
            form: {
                nisn: {
                    required,
                },
                nis: {
                    required,
                },
                first_name: {
                    required,
                },
                date_of_birth: {
                    required,
                },
                place_of_birth: {
                    required,
                },
                address: {
                    required,
                },
                phone_number: {
                    required,
                },
                email_address: {
                    required,
                    email,
                },
                status: {
                    required,
                },

            }
        }
    },
    methods: {
        async submit() {
            
            const validate = await this.v$.form.$validate();

            if (!validate) {
                return;
            }
        
            this.loading = true;
            try {
                const response = await api.POST(settings.STUDENT_URI, StudentModel.toJson(this.form));
                if (response.data.success) {
                    this.$router.push({ path: route_name.student.student });
                }
            } catch (error) {
                console.log(error);
            }

            this.loading = false;
        },
        async fetchStudentStatus(){
            this.statuses = [];
            try {
                const response = await api.GET(settings.STUDENT_URI_STATUS);
                
                if (response.success) {
                    
                    response.content.forEach(element => {
                        this.statuses.push(Status.fromJson(element));
                    });
                    this.form.status = this.statuses[0].slug;
                }
            } catch (error) {
                console.log(error);
            }

            console.log(this.form.status);
        
        }
    },
    created() {
        this.fetchStudentStatus();
    }
}
</script>

