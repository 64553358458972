export default class StatusStudent {
    slug = "student-active";
    name = "Aktif";

    constructor(slug, name) {
        this.slug = slug;
        this.name = name;
    }

    static fromJson(json) {
        return new StatusStudent(json.slug, json.name);
    }
}