export default class Subject {
    constructor(level, name, code, is_active) {
        this.level = level;
        this.name = name;
        this.code = code;
        this.is_active = is_active;
    }

    static fromJson(json) {
        return new Subject(json.level, json.name, json.code, json.is_active);
    }
}